import React, { useEffect, useState } from "react";
import styles from "./message.module.css";
import axios from "axios";
import debounce from "lodash.debounce";
import { createIcon } from "../../utils/WallFunctions/Blockies";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Comment } from "../../assets/comment.svg";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_WALL,
});

export default function MessageContainer({ page, isMessage }) {
  const [messageList, setMessageList] = useState([]);
  const [tempIncomingMessagesList, setTempIncomingMessagesList] = useState([]);
  const [tempOlderMessagesList, setTempOlderMessagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function generateAvatar(seed) {
    var canvas = createIcon({ seed, size: 10, scale: 4.7 });
    return canvas.toDataURL();
  }

  const getMessagesAfter = () => {
    api
      .post("/get-messages-after", {
        lastMessageId: messageList[messageList.length - 1].id,
      })
      .then((response) => {
        setTempOlderMessagesList(response.data.data);
      });
  };

  useEffect(() => {
    if (tempOlderMessagesList && tempOlderMessagesList.length > 0) {
      let _messageList = messageList;
      let _tempMessageList = tempOlderMessagesList;

      let newArrayToDisplay = [..._messageList, ..._tempMessageList];
      setMessageList(newArrayToDisplay);
      setTempOlderMessagesList([]);
    }
  }, [tempOlderMessagesList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tempIncomingMessagesList && tempIncomingMessagesList.length > 0) {
      let _messageList = messageList;
      let _tempMessageList = tempIncomingMessagesList;

      let newArrayToDisplay = [..._tempMessageList, ..._messageList];
      setMessageList(newArrayToDisplay);
      setTempIncomingMessagesList([]);
    }
  }, [tempIncomingMessagesList]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllPosts = () => {
    setLoading(true);
    api
      .get("/get-all-posts")
      .then((response) => {
        setLoading(false);
        if (page === "home") {
          setMessageList(response.data.data.slice(0, 3));
        } else {
          setMessageList(response.data.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    getAllPosts();
  }, [isMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  window.onscroll = debounce(() => {
    let scrollHeight, totalHeight;
    scrollHeight = document.body.scrollHeight;
    totalHeight = window.scrollY + window.innerHeight;
    if (totalHeight >= scrollHeight - 450 && page !== "home") {
      if (messageList && page !== "home") {
        getMessagesAfter();
      }
    }
  }, 1);

  useEffect(() => {
    const interval = setInterval(() => {
      let element = document.getElementsByClassName("msg-id")[0];
      if (!element) {
        return;
      }

      let elementId = element.innerHTML;
      api
        .post("/get-new-messages", {
          id: elementId,
        })
        .then((response) => {
          if (response.data.error === true) {
            return;
          }
          setTempIncomingMessagesList(response.data.data);
        });
    }, 3000);
    return () => clearInterval(interval);
  }, [isMessage]);

  return (
    <>
      {loading ? (
        <div className={styles.shimmer}>Loading...</div>
      ) : (
        <>
          {page === "home"
            ? messageList.slice(0, 3).map((message, i) => {
                return (
                  <div key={i} className={styles.messageList}>
                    <div
                      className={styles.container}
                      onClick={() =>
                        navigate("/post-detail/" + message.tx_hash)
                      }
                    >
                      <div className={styles.logo}>
                        <img
                          src={generateAvatar(message.sender_address)}
                          alt=""
                        />
                      </div>
                      <div className={styles.messageInfo}>
                        <div className={styles.user}>
                          <div className={styles.username}>{message.owner}</div>
                          <div className={styles.address}>
                            {message.sender_address.substr(0, 6)}
                          </div>
                        </div>
                        <div className={styles.message}>
                          {message.message.length > 126
                            ? message.message.substring(0, 126) + "..."
                            : message.message}
                        </div>
                      </div>
                      <div className={styles.comments}>
                        <div>
                          <Comment />
                        </div>
                        <div className={styles.count}>
                          {message.child_message_count}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : messageList.map((message, i) => {
                return (
                  <div key={i} className={styles.messageList}>
                    <div
                      className={styles.container}
                      onClick={() =>
                        navigate("/post-detail/" + message.tx_hash)
                      }
                    >
                      <div className={styles.logo}>
                        <img
                          src={generateAvatar(message.sender_address)}
                          alt=""
                        />
                      </div>
                      <div className={styles.messageInfo}>
                        <div className={styles.user}>
                          <div className={styles.username}>{message.owner}</div>
                          <div className={styles.address}>
                            {message.sender_address.substr(0, 6)}
                          </div>
                        </div>
                        <div className={styles.message}>
                          {page === "home"
                            ? message.message.substring(0, 126) + "..."
                            : message.message}
                        </div>
                      </div>
                      <div className={styles.comments}>
                        <div>
                          <Comment />
                        </div>
                        <div className={styles.count}>
                          {message.child_message_count}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </>
      )}
    </>
  );
}
