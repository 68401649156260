import React from "react";
import styles from "./date.module.css";
import {ReactComponent as Calendar} from "../../../assets/calendar.svg"

export default function DateContainer({title, date}) {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
          <Calendar />
      </div>
      <div className={styles.title}>
          {title}
      </div>
      <div className={styles.date}>
          {date}
      </div>
    </div>
  );
}
