import React, { useState, useContext, useEffect } from "react";
import styles from "./stake.module.css";

import { AppContext } from "../../utils/Context";
import Header from "../../components/HomeComponents/Header/Header";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";
import BalanceContainer from "../../components/StakeComponents/BalanceContainer/BalanceContainer";
import NewInfoContainer from "../../components/StakeComponents/InfoContainer/NewInfoContainer";
import EnterAmountContainerV2 from "../../components/StakeComponents/EnterAmountContainer/EnterAmountContainerV2";
import StakeCard from "../../components/StakeComponents/StakeCard/StakeCard";
import WithDrawalCard from "../../components/StakeComponents/WithDrawalCard/WithDrawalCard";
import { getWithdrawals } from "../../utils/StakeFunctions/StakeV2Functions";

export default function StakeV2({getUserStakes}) {
  const isConnected = useContext(AppContext);
  const account = useContext(AppContext).connectedAccount;
  const connectionStatus = useContext(AppContext).connectionStatus;
  const isCorrectNetworkForStake =
    useContext(AppContext).isCorrectNetworkForStake;
  const [showWrongModal, setShowWrongModal] = useState(false);
  const [renderHelper, setRenderHelper] = useState(false);
  const [amount, setAmount] = useState("");

  const [userStakes, setUserStakes] = useState([]);
  const [userWithdrawals, setUserWithdrawals] = useState([]);

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetworkForStake) {
        setShowWrongModal(false);
        getUserStakes(account).then((res) => {
          setUserStakes(res);
        });
        getWithdrawals().then((res) => {
          setUserWithdrawals(res);
        });
      } else {
        setShowWrongModal(true);
      }
    }
  }, [connectionStatus, isCorrectNetworkForStake, renderHelper]);

  return (
    <div>
      <Header text={"Stake your Omchain to earn rewards"} />
      {/* Balance Container */}
      {isConnected.connectionStatus && isCorrectNetworkForStake ? (
        <BalanceContainer />
      ) : (
        <div className={styles.balance}></div>
      )}

      {/* Stake Info Container */}
      {isConnected.connectionStatus && isCorrectNetworkForStake ? (
        <div>
          <NewInfoContainer showWrongModal={showWrongModal} />
        </div>
      ) : (
        <div className={styles.info}></div>
      )}

      {/* Enter Amount Container */}
      {isCorrectNetworkForStake && account ? (
        <EnterAmountContainerV2
          amount={amount}
          setAmount={setAmount}
          renderHelper={renderHelper}
          setRenderHelper={setRenderHelper}
        />
      ) : (
        <div className={styles.empty}>
          This page cannot be accessed without connecting a wallet.
        </div>
      )}


      {/* Stakes Container */}
      {isConnected.connectionStatus && userStakes.length > 0 ? (
        <div className={styles.stakeList}>
          {isConnected.connectionStatus &&
            userStakes.length > 0 && (
              <div className={styles.stakeTitle}>Stakes</div>
            )}

          {isConnected.connectionStatus &&
            userStakes.length &&
            userStakes.map((stake, i) => {
              return (
                <div key={i}>
                  <StakeCard stake={userStakes[i]} i={i} />
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}

      {/* Withdrawals Container */}
      {isConnected.connectionStatus && userWithdrawals.length > 0 ? (
        <div className={styles.stakeList}>
          <div className={styles.stakeTitle}>Withdrawals</div>

          {isConnected.connectionStatus &&
            userWithdrawals.length &&
            userWithdrawals.map((withdrawal, i) => {
              return (
                <div key={i}>
                  <WithDrawalCard 
                    withdrawal={userWithdrawals[i]} 
                    i={i}
                    setRenderHelper={setRenderHelper}
                    renderHelper={renderHelper} 
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}



      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
