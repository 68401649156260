import React, { useState, useContext } from "react";
import styles from "./addStake.module.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Back } from "../../../assets/arrowLeft.svg";
import { ReactComponent as Omc } from "../../../assets/omcIcon.svg";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { AppContext } from "../../../utils/Context";
import { addStakeAction } from "../../../utils/StakeFunctions/StakeV2Functions";

export default function AddStakeModal({
  showModal,
  setShowModal,
  amount,
  stakeTerm,
  renderHelper,
  setRenderHelper,
  getUserStakes,
}) {
  const [loading, setLoading] = useState(false);
  let balance = useContext(AppContext).accountBalance;
  async function handleAccept() {
    setLoading(true);
    try {
      let status = await addStakeAction(amount, stakeTerm);
      setRenderHelper(!renderHelper);
      if (status === -1) {
        setLoading(false);
      }
      setLoading(false);
      if (status === true) {
        setShowModal(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const stakeTerms = [
    '',
    '30 Days',
    '60 Days',
    '180 Days',
    '360 Days',
    '720 Days'
  ];
  return (
    <Modal
      show={showModal}
      onHide={!showModal}
      animation={true}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div onClick={() => setShowModal(false)} className={styles.back}>
          <Back />
        </div>
        <div className={styles.title}>Add Stake</div>
        <div className={styles.balance}>Balance: {parseFloat(balance).toLocaleString("en", { minimumFractionDigits: 4 })}</div>
        <div className={styles.amount}>
          <div className={styles.logo}>
            <Omc />
          </div>
          <div>{parseFloat(amount).toLocaleString("en")}</div>
        </div>
        <div className={styles.term}>Stake Term: {stakeTerms[stakeTerm]}</div>
        <div className={styles.messageContainer}>
          <div className={styles.warning}>
            <Warning />
          </div>
          <div className={styles.message}>
            There is no early unstaking option for this stake.<br />This operation cannot be undone.<br />
            Are you sure you want to proceed?
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div>
            <button
              className={styles.cancel}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={styles.accept}
              onClick={handleAccept}
              disabled={loading}
            >
              {loading ? (
                <div className={styles.shimmer}>Waiting response...</div>
              ) : (
                "Accept"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
