import React, { useState, useContext, useEffect } from "react";
import styles from "./detail.module.css";
import { ProgressBar } from "react-bootstrap";
import { AppContext } from "../../utils/Context";
import { Web3Context } from "../../utils/Web3Context";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";
import {
  postVerifyWithWallet,
  verifySignedMessage,
} from "../../utils/VoteFunctions/SignMessage";
import axios from "axios";
import { useParams } from "react-router-dom";
import { createIcon } from "../../utils/WallFunctions/Blockies";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export default function Detail() {
  const connectionStatus = useContext(AppContext).connectionStatus;
  const web3 = useContext(Web3Context).web3;
  const isCorrectNetwork = useContext(AppContext).isCorrectNetworkForStake;
  const account = useContext(AppContext).connectedAccount;
  const [showWrongModal, setShowWrongModal] = useState(false);
  const [signed, setSigned] = useState(false);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [voteStatus, setVoteStatus] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [dataCount, setDataCount] = useState(10);

  let { id } = useParams();
  let { tab } = useParams();

  function generateAvatar(seed) {
    var canvas = createIcon({ seed, size: 10, scale: 4.7 });
    return canvas.toDataURL();
  }

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetwork) {
        setShowWrongModal(false);
      } else {
        setShowWrongModal(true);
      }
    }
  }, [connectionStatus, isCorrectNetwork]);

  useEffect(() => {
    if (account) {
      api
        .get(`ovote/user-status/${account}`)
        .then((res) => setSigned(res.data.data));
    }
  }, [account]);

  const getDetail = () => {
    setLoading(true);
    api
      .get(`ovote/proposal/${id}`)
      .then((res) => {
        setDetail(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  });

  const checkStatus = () => {
    api
      .post("ovote/can-vote", {
        proposalId: id,
        walletAddress: account,
      })
      .then((res) => setVoteStatus(res.data.data))
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (account) {
      checkStatus();
    }
  // eslint-disable-next-line
  }, [account]);

  async function signMessage() {
    let returnStatus = await postVerifyWithWallet(account);
    if (returnStatus === -1) {
      return -1;
    }
    let text = returnStatus;
    try {
      let signedMessage = await web3.eth.personal.sign(text, account);
      let returnStatusX = await verifySignedMessage(signedMessage, account);
      if (returnStatusX !== -1) {
        setSigned(true);
      } else {
        return -1;
      }
    } catch (exception) {
      console.error(exception);
      return -1;
    }
  }

  const registerVote = () => {
    api
      .post(`ovote/register-vote`, {
        proposalId: id,
        optionId: selectedOption,
        walletAddress: account,
      })
      .then(() => {
        checkStatus();
        getDetail();
      })
      .catch((e) => console.error(e));
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.empty}>
          <div className={styles.shimmer}>Loading...</div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.contentOne}>
            <div className={styles.info}>
              <div className={styles.title}>
                <div>{detail !== null && detail.name}</div>
              </div>
              <div
                className={
                  detail !== null && detail.status === "active"
                    ? styles.status
                    : detail !== null && detail.status === "ended"
                    ? styles.endStatus
                    : styles.pendingStatus
                }
              >
                {detail !== null &&
                detail !== null &&
                detail.status === "active"
                  ? "Active"
                  : detail !== null && detail.status === "ended"
                  ? "Closed"
                  : "Pending"}
              </div>
              {detail !== null && (
                <div>
                  <div className={styles.detailTitle}>Description</div>
                  <div
                    dangerouslySetInnerHTML={
                      detail !== null &&
                      detail.description &&
                      detail.description.length > 500
                        ? showMore
                          ? { __html: detail.description }
                          : {
                              __html:
                                detail.description.substring(0, 500) + "...",
                            }
                        : { __html: detail.description }
                    }
                    className={styles.text}
                  ></div>
                  <div>
                    {detail !== null &&
                    detail.description &&
                    detail.description.length > 500 ? (
                      showMore ? (
                        <button
                          className={styles.more}
                          onClick={() => setShowMore(false)}
                        >
                          Show Less
                        </button>
                      ) : (
                        <button
                          className={styles.more}
                          onClick={() => setShowMore(true)}
                        >
                          Show More
                        </button>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {detail !== null && detail.status === "active" ? (
              <div className={styles.vote}>
                <h4 className={styles.voteTitle}>Cast your vote</h4>
                <hr />
                {signed ? (
                  <div className={styles.options}>
                    {voteStatus ? (
                      <div className={styles.alert}>
                        * You voted for this proposal.
                      </div>
                    ) : (
                      detail !== null &&
                      detail.options.length !== 0 &&
                      detail?.options.map((option) => {
                        return (
                          <div
                            onClick={() => setSelectedOption(option.optionId)}
                            className={
                              selectedOption === option.optionId
                                ? styles.selectedOption
                                : styles.option
                            }
                          >
                            <div>{option.option}</div>
                          </div>
                        );
                      })
                    )}
                    {!voteStatus && (
                      <div onClick={registerVote}>
                        <div
                          className={
                            selectedOption === ""
                              ? styles.optionDis
                              : styles.option2
                          }
                        >
                          <div>Vote</div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.options}>
                    <div className={styles.option} onClick={signMessage}>
                      <div>Verify Wallet</div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}

            <div className={styles.vote}>
              <h4 className={styles.voteTitle}>Votes</h4>
              <hr />
              {detail !== null &&
                detail.votes.slice(0, dataCount).map((data, i) => {
                  return (
                    <div key={i} className={styles.voteR}>
                      <div className={styles.voteText}>
                        <img
                          alt="avatar"
                          className={styles.voteImg}
                          src={generateAvatar(data.walletAddress)}
                        />
                        <div className={styles.voteAdr}>
                          {data.walletAddress.substring(0, 16) + "..."}
                        </div>
                      </div>
                      <div>{data.selectedOption}</div>
                      <div>{data.balance}</div>
                    </div>
                  );
                })}
              {detail !== null &&
                dataCount !== detail.votes.length &&
                dataCount < detail.votes.length && (
                  <div
                    className={styles.moreVote}
                    onClick={() => {
                      setDataCount(dataCount + 1);
                    }}
                  >
                    <div>See More</div>
                  </div>
                )}
            </div>

            {tab === "active" && (
              <div className={styles.alert}>
                Remaining Time: {detail !== null && detail.remaining_time}
              </div>
            )}
            <div className={styles.alert}>
              * The results are updated every hour based on the total OMC
              participated on the proposal.
            </div>
          </div>
          <div className={styles.contentTwo}>
            <div className={styles.infoCard}>
              <h4 className={styles.voteTitle}>Information</h4>
              <hr />
              <div className={styles.infoText}>
                <b>Start Date</b>
                <div>{detail !== null && detail.starts_at}</div>
              </div>
              <div className={styles.infoText}>
                <b>End Date</b>
                <div>{detail !== null && detail.ends_at}</div>
              </div>
            </div>
            <div className={styles.infoCard}>
              <h4 className={styles.voteTitle}>Current Results</h4>
              <hr />
              {detail !== null &&
                detail?.options.map((option) => {
                  return (
                    <div className={styles.progress}>
                      <div className={styles.barText}>
                        <div>{option.option}</div>
                        <div>%{option.rate}</div>
                      </div>
                      <ProgressBar className={styles.bar} now={option.rate} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
