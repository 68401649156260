import React, { useState } from "react";
import styles from "./unstake.module.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Back } from "../../../assets/arrowLeft.svg";
import { ReactComponent as Omc } from "../../../assets/omcIcon.svg";
import { callExitAction } from "../../../utils/StakeFunctions/StakeV2Functions";
import { useNavigate } from "react-router-dom";

export default function UnstakeModal({
  showUnstakeModal,
  setShowUnstakeModal,
  index,
  userReward,
  getUserStakes
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleExit() {
    setLoading(true);
    try {
      let status = await callExitAction(index);
      setLoading(false);
      if (status === true) {
        navigate("/stake");
        getUserStakes();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={showUnstakeModal}
      onHide={!showUnstakeModal}
      animation={true}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div onClick={() => setShowUnstakeModal(false)} className={styles.back}>
          <Back />
        </div>
        <div className={styles.title}>Unstake</div>
        <div className={styles.amount}>
          <div className={styles.logo}>
            <Omc />
          </div>
          <div>{parseFloat(userReward).toLocaleString("en", { minimumFractionDigits: 4 })}</div>
        </div>
        <div className={styles.cards}></div>
        <div className={styles.buttonContainer}>
          <div>
            <button
              className={styles.cancel}
              onClick={() => setShowUnstakeModal(false)}
            >
              Cancel
            </button>
          </div>
          <div>
            <button className={styles.accept} onClick={handleExit} disabled={loading}>
              {loading ? (
                <div className={styles.shimmer}>Waiting response...</div>
              ) : (
                "Accept"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
