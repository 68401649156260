import { getWeb3Object } from "../../App";
import getAbi from "./Abi";

const abi = getAbi();

export async function signMint(result, tokenIds) {
  try {
    let web3 = await getWeb3Object();
    let accounts = await web3.eth.getAccounts();
    let currentAccount = accounts[0].toLowerCase();
    let contract = await new web3.eth.Contract(
      abi,
      process.env.REACT_APP_NFT_MINT_CONTRACT_ADDRESS
    );
    let receipt = await contract.methods.signMint(result, tokenIds).send({
      from: currentAccount,
    });

    return receipt["status"];
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function safeTransferFrom(
  walletAddress,
  recWalletAddress,
  tokenId
) {
  try {
    let web3 = await getWeb3Object();
    let accounts = await web3.eth.getAccounts();
    let currentAccount = accounts[0].toLowerCase();
    let contract = await new web3.eth.Contract(
      abi,
      process.env.REACT_APP_NFT_MINT_CONTRACT_ADDRESS
    );
    let receipt = await contract.methods
      .safeTransferFrom(walletAddress, recWalletAddress, tokenId)
      .send({
        from: currentAccount,
      });

    return receipt["status"];
  } catch (e) {
    console.error(e);
    return false;
  }
}
