import React from "react";
import styles from "./card.module.css";
import { useNavigate } from "react-router-dom";

export default function ProposalCard({
  name,
  id,
  date,
  tab,
  image,
  description,
  status,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/vote-detail/${id}/${tab}`)}
    >
      <div className={styles.header}>
        {status === "active" && <div className={styles.status}>Active</div>}
        {status === "ended" && <div className={styles.endStatus}>Closed</div>}
        {status === "scheduled" && (
          <div className={styles.pendingStatus}>Pending</div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <h5 className={styles.header}>{name}</h5>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: description && description.substring(0, 184) + "...",
          }}
          className={styles.description}
        ></div>
      </div>
      <div className={styles.section}>
        <div className={styles.bottom}>{date}</div>
      </div>
    </div>
  );
}
