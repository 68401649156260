import React from "react";
import styles from "./social.module.css";
import { ReactComponent as Twitter } from "../../../assets/twitter.svg";
import { ReactComponent as Linkedin } from "../../../assets/linkedin.svg";
import { ReactComponent as Telegram } from "../../../assets/telegram.svg";
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";

export default function SocialBar({ isMobile }) {
  return (
    <div
      className={isMobile ? `${styles.containerMobile}` : `${styles.container}`}
    >
      <a href="https://twitter.com/omchainio" target="_blank" rel="noreferrer">
        <div>
          <Twitter />
        </div>
      </a>
      <a
        href="https://www.linkedin.com/company/openmoneytr/"
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <Linkedin />
        </div>
      </a>
      <a href="https://t.me/omChainio" target="_blank" rel="noreferrer">
        <div>
          <Telegram />
        </div>
      </a>
      <a
        href="https://www.instagram.com/omchainio/"
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <Instagram />
        </div>
      </a>
      <a
        href="https://www.facebook.com/omchainio"
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <Facebook />
        </div>
      </a>
    </div>
  );
}
