import React from "react";
import styles from "./amount.module.css";
import AnimatedNumbers from "react-animated-numbers";

export default function StakeAmount({ title, amount, type }) {
  let theme = "";
  if (localStorage.getItem("theme") === "light") {
    theme = "linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%)";
  } else {
    theme = "linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%)";
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {type === "stake" ? (
        <div className={styles.amount}>{amount}</div>
      ) : (
        <AnimatedNumbers
          animateToNumber={amount}
          className={styles.amount}
          fontStyle={{
            fontSize: 36,
            fontFamily: "Montserrat",
            fontWeight: 600,
            background: theme,
            backgroundClip: "text",
            textFillColor: "transparent",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          configs={[
            { mass: 1, tension: 220, friction: 100 },
            { mass: 1, tension: 180, friction: 130 },
            { mass: 1, tension: 280, friction: 90 },
            { mass: 1, tension: 180, friction: 135 },
            { mass: 1, tension: 260, friction: 100 },
            { mass: 1, tension: 210, friction: 180 },
          ]}
        ></AnimatedNumbers>
      )}
    </div>
  );
}
