import React from "react";
import styles from "./sendButton.module.css";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";

export default function SendButton({
  allowanceStatus,
  submitApprove,
  submitDeposit,
  walletConnect,
  loading,
}) {
  const isConnected = useContext(AppContext);

  return (
    <div className={styles.container}>
      {!isConnected.connectionStatus ? (
        <div>
          <button className={styles.button} onClick={walletConnect}>
            Connect Wallet
          </button>
        </div>
      ) : (
        <div>
          {allowanceStatus === false ? (
            <button className={styles.button} onClick={submitApprove} disabled={loading}>
              {loading ? (
                <div className={styles.shimmer}>Waiting response...</div>
              ) : (
                "Approve"
              )}
            </button>
          ) : (
            <button className={styles.button} onClick={submitDeposit} disabled={loading}>
               {loading ? (
                <div className={styles.shimmer}>Waiting response...</div>
              ) : (
                "Send"
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
