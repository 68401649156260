import React from "react";
import styles from "./image.module.css";

export default function NftImage({detail}) {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img alt='NFT' src={detail && detail.image} />
      </div>
    </div>
  );
}
