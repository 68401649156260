import React from "react";
import styles from "./newInfo.module.css";
export default function NewInfoContainer() {

  return (
    <div className={styles.container}>
      <div className={styles.planCard}>
        <div className={styles.planCardTitle}>30 Days</div>
        <div className={styles.planCardInfoBox}>
          <table className={styles.planCardInfoTable}>
            <tbody>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Stake</td>
                <td className={styles.planCardInfoTableValue}>100 OMC</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Stake Constant</td>
                <td className={styles.planCardInfoTableValue}>6x</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Duration</td>
                <td className={styles.planCardInfoTableValue}>30 Days</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Unlock Duration</td>
                <td className={styles.planCardInfoTableValue}>1 Day</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.planCard}>
        <div className={styles.planCardTitle}>60 Days</div>
        <div className={styles.planCardInfoBox}>
          <table className={styles.planCardInfoTable}>
            <tbody>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Stake</td>
                <td className={styles.planCardInfoTableValue}>100 OMC</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Stake Constant</td>
                <td className={styles.planCardInfoTableValue}>8x</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Duration</td>
                <td className={styles.planCardInfoTableValue}>60 Days</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Unlock Duration</td>
                <td className={styles.planCardInfoTableValue}>2 Days</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.planCard}>
        <div className={styles.planCardTitle}>180 Days</div>
        <div className={styles.planCardInfoBox}>
          <table className={styles.planCardInfoTable}>
            <tbody>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Stake</td>
                <td className={styles.planCardInfoTableValue}>100 OMC</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Stake Constant</td>
                <td className={styles.planCardInfoTableValue}>13x</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Duration</td>
                <td className={styles.planCardInfoTableValue}>180 Days</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Unlock Duration</td>
                <td className={styles.planCardInfoTableValue}>3 Days</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.planCard}>
        <div className={styles.planCardTitle}>360 Days</div>
        <div className={styles.planCardInfoBox}>
          <table className={styles.planCardInfoTable}>
            <tbody>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Stake</td>
                <td className={styles.planCardInfoTableValue}>100 OMC</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Stake Constant</td>
                <td className={styles.planCardInfoTableValue}>25x</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Duration</td>
                <td className={styles.planCardInfoTableValue}>360 Days</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Unlock Duration</td>
                <td className={styles.planCardInfoTableValue}>7 Days</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.planCard}>
        <div className={styles.planCardTitle}>720 Days</div>
        <div className={styles.planCardInfoBox}>
          <table className={styles.planCardInfoTable}>
            <tbody>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Stake</td>
                <td className={styles.planCardInfoTableValue}>100 OMC</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Stake Constant</td>
                <td className={styles.planCardInfoTableValue}>40x</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Minimum Duration</td>
                <td className={styles.planCardInfoTableValue}>720 Days</td>
              </tr>
              <tr>
                <td className={styles.planCardInfoTableTitle}>Unlock Duration</td>
                <td className={styles.planCardInfoTableValue}>14 Days</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}
