import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export async function postVerifyWithWallet(walletAddress) {
  let x = await api
    .post("ovote/text-to-sign", {
      walletAddress: walletAddress,
    })
    .then((response) => {
      let textToSign = response.data.data;
      return textToSign;
    })
    .catch((error) => {
      console.error(error);
      return -1;
    });
  return x;
}

export async function verifySignedMessage(signedMessage, walletAddress) {
  api
    .post("ovote/verify-signed-message", {
      signedMessage: signedMessage,
      walletAddress: walletAddress,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return -1;
    });
}
