import React, { useEffect, useState, useContext } from "react";
import styles from "./wall.module.css";
import WriteContainer from "../../components/WallComponents/WriteContainer";
import MessageContainer from "../../components/WallComponents/MessageContainer";
import { AppContext } from "../../utils/Context";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";

export default function Wall() {
  const [isMessage, setIsMessage] = useState(false);
  const [showWrongModal, setShowWrongModal] = useState(false);
  const isCorrectNetworkForStake =
    useContext(AppContext).isCorrectNetworkForStake;
  const connectionStatus = useContext(AppContext).connectionStatus;

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetworkForStake) {
        setShowWrongModal(false);
      } else {
        setShowWrongModal(true);
      }
    }
  }, [connectionStatus, isCorrectNetworkForStake]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={styles.write}>
        <WriteContainer isMessage={isMessage} setIsMessage={setIsMessage} />
      </div>
      <div className={styles.message}>
        <MessageContainer isMessage={isMessage} />
      </div>
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
