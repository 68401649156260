import React from "react";
import styles from "./child.module.css";
import { createIcon } from "../../utils/WallFunctions/Blockies";

export default function ChildPosts({ replies }) {
  function generateAvatar(seed) {
    var canvas = createIcon({ seed, size: 10, scale: 4.7 });
    return canvas.toDataURL();
  }
  return (
    <div className={styles.messageList}>
      <div className={styles.comments}>Comments</div>
      {replies.length > 0 ? (
        <>
          {replies.map((reply, i) => {
            return (
              <div key={i} className={styles.container}>
                <div className={styles.logo}>
                  <img src={generateAvatar(reply.sender_address)} alt="" />
                </div>
                <div className={styles.messageInfo}>
                  <div className={styles.user}>
                    <div className={styles.username}>{reply.owner}</div>
                    <div className={styles.address}>
                      {reply.sender_address.substr(0, 6)}
                    </div>
                  </div>
                  <div className={styles.message}>{reply.message}</div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noComment}>This post has no comment.</div>
      )}
    </div>
  );
}
