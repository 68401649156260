import React from "react";
import styles from "./footer.module.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoWh } from "../../assets/logoWh.svg";

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.line}></div>
      <div className={styles.horizontal}>
        <div className={styles.logo}>
          {localStorage.getItem("theme") === "dark" ? <LogoWh /> : <Logo />}
        </div>
        <div className={styles.text}>
          &copy; 2023 Omchain
        </div>
        <div className={styles.powered}>
          <div>Powered by</div>
          <div className={styles.openMoney}>
            Omchain Foundation
          </div>
        </div>
      </div>
    </div>
  );
}
