import React, { useState, useRef, useEffect, memo } from "react";
import { formatTime } from "../../utils/BridgeFunctions/FormatTime";

const TicketTimer = ({ status, confirmedTime }) => {
  const [timer, setTimer] = useState(0);
  const countRef1 = useRef(null);

  const calculateNotMinusConfirmedTime = () => {
    let depositConfirmedTime = new Date(confirmedTime).getTime();
    let now = Date.now();
    let diffTime = now - depositConfirmedTime;
    return Math.trunc(diffTime / 1000);
  };

  const handleStart = () => {
    countRef1.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(countRef1.current);
  };

  useEffect(() => {
    if (status === 0 || status === 1) {
      handlePause();
      setTimer(calculateNotMinusConfirmedTime());
      handleStart();
    } else {
      handlePause();
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="app">
      <div className="stopwatch-card">
        {status ? <p>{formatTime(timer)}</p> : <p>00:00</p>}
      </div>
    </div>
  );
};

export default memo(TicketTimer);
