import { getErcAbi } from "./Erc20Abi";
import { getAbi } from "./Abi";
import { getChainData } from "./ChainData";
import {
  sendDepositCreatedRequest,
  sendLinkFinishedRequest,
  sendLinkStartedRequest,
} from "./ApiUtils";

const abi = getAbi();
const ercAbi = getErcAbi();

export async function actionAllowance(
  mainWeb3Object,
  tokenAddress,
  selectedAccount,
  omLinkAddress,
  enteredAmount,
  decimals
) {
  if (!enteredAmount) {
    return false;
  }
  let allowanceStatus = false;
  try {
    let address = mainWeb3Object.utils.toChecksumAddress(tokenAddress);
    let contract = await new mainWeb3Object.eth.Contract(ercAbi, address);

    let allowanceAmount = await contract.methods
      .allowance(selectedAccount, omLinkAddress)
      .call();

    // Parse the allowanceAmount as a BigNumber (assuming you have a library for it)
    const BigNumber = require("bignumber.js");
    let allowanceAmountBigNumber = new BigNumber(allowanceAmount);

    // Assuming tokenDecimals is the number of decimal places for the token
    let tokenDecimalsBigNumber = new BigNumber(10).exponentiatedBy(decimals);

    // Parse enteredAmount as a BigNumber
    let enteredAmountBigNumber = new BigNumber(enteredAmount);

    // Adjust the allowanceAmount by dividing it by 10^tokenDecimals
    allowanceAmountBigNumber = allowanceAmountBigNumber.dividedBy(tokenDecimalsBigNumber);

    // Compare allowanceAmountBigNumber with enteredAmountBigNumber
    if (allowanceAmountBigNumber.isGreaterThanOrEqualTo(enteredAmountBigNumber)) {
      allowanceStatus = true;
      }
    } catch (e) {
    allowanceStatus = false;
  }
  return allowanceStatus;
}

export async function actionApprove(
  mainWeb3Object,
  tokenAddress,
  selectedAccount,
  omLinkAddress,
  gasPrice_
) {
  let status = false;
  if (gasPrice_ === false) {
    gasPrice_ = await mainWeb3Object.eth.getGasPrice();
  } else {
    gasPrice_ = mainWeb3Object.utils.toWei(gasPrice_.toString(), "wei");
  }

  try {
    let contract = await new mainWeb3Object.eth.Contract(ercAbi, tokenAddress);
    let approvalAmount =
      "999999999999999999999999999999999999999999999"; //todo: change in prod
    status = await contract.methods
      .approve(omLinkAddress, approvalAmount)
      .send({
        from: selectedAccount,
        gasPrice: gasPrice_,
      });
  } catch (e) {
    return false;
  }
  return status;
}

export async function depositCrafter(
  web3,
  withdrawNetwork,
  depositNetwork,
  tokenSymbol,
  receiver,
  sender,
  depositAmount
) {
  let chainData = getChainData();
  let fromChain = chainData.supportedNetworks[depositNetwork];
  let toChain = chainData.supportedNetworks[withdrawNetwork];
  let decimals = chainData.supportedTokens[tokenSymbol].decimals[depositNetwork];
  let depositType = null;

  if (
    fromChain.isNativeSupported === true &&
    fromChain.symbol === tokenSymbol
  ) {
    depositType = "depositNative";
  } else {
    depositType = "deposit";
  }

  let token = chainData.supportedTokens[tokenSymbol];

  try {
    let contract = await new web3.eth.Contract(abi, fromChain.omlink);
    const BigNumber = require("bignumber.js");
    let depositAmountBigNumber = new BigNumber(depositAmount);

    // Adjust depositAmount by multiplying it by 10^decimals
    let multiplier = new BigNumber(10).exponentiatedBy(decimals);
    depositAmountBigNumber = depositAmountBigNumber.multipliedBy(multiplier);

    // Convert depositAmountBigNumber to a string to ensure precision
    depositAmount = depositAmountBigNumber.toString(10);

    if (fromChain.gasPrice === false) {
      fromChain.gasPrice = await web3.eth.getGasPrice();
    } else {
      fromChain.gasPrice = web3.utils.toWei(fromChain.gasPrice.toString(), "wei");
    }

    if (depositType === "depositNative") {
      await contract.methods
        .depositNative(toChain.chainId, receiver, depositAmount)
        .send({
          from: sender,
          gasPrice: fromChain.gasPrice,
          value: depositAmount,
        })
        .on("transactionHash", function (hash_) {
          let depositCreatedStatus = sendDepositCreatedRequest(
            hash_,
            sender,
            receiver,
            "native",
            tokenSymbol,
            toChain.chainId,
            fromChain.chainId,
            depositAmount,
            token.contracts[toChain.chainId]
          );
          if (depositCreatedStatus === "error") {
            return "Error while getting tx hash, please contact us.";
          }
        });
    } else {
      await contract.methods
        .deposit(
          toChain.chainId,
          token.contracts[fromChain.chainId],
          receiver,
          depositAmount
        )
        .send({
          from: sender,
          gasPrice: fromChain.gasPrice,
        })
        .on("transactionHash", function (hash_) {
          let depositCreatedStatus = sendDepositCreatedRequest(
            hash_,
            sender,
            receiver,
            token.contracts[fromChain.chainId],
            tokenSymbol,
            toChain.chainId,
            fromChain.chainId,
            depositAmount,
            token.contracts[toChain.chainId]
          );
          if (depositCreatedStatus === "error") {
            return "Error while getting tx hash, please contact us.";
          }
        });
    }
  } catch (e) {
    console.error(e);
  }
  return "";
}

export async function switchNetwork(provider, argChainId) {
  let supportedNetworks = getChainData().supportedNetworks;
  let selectedChain = supportedNetworks[argChainId];
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: selectedChain.chainIdHex }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: selectedChain.chainIdHex,
              chainName: selectedChain.name,
              nativeCurrency: {
                name: selectedChain.nativeCurrency,
                symbol: selectedChain.symbol,
                decimals: selectedChain.decimals,
              },
              rpcUrls: [selectedChain.rpcUrl],
              blockExplorerUrls: [selectedChain.blockExplorerUrl],
            },
          ],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
  }
}

export async function addToken(
  currentChainId,
  provider,
  withdrawNetwork,
  argTokenSymbol
) {
  if (parseInt(withdrawNetwork) !== parseInt(currentChainId)) {
    return "Network on your metamask and receiver network must match !";
  }

  let supportedNetworks = getChainData().supportedNetworks;
  let selectedChain = supportedNetworks[withdrawNetwork];

  let supportedTokens = getChainData().supportedTokens;
  let supportedToken = supportedTokens[argTokenSymbol];

  const tokenAddress = supportedToken.contracts[parseInt(withdrawNetwork)];
  const tokenSymbol = argTokenSymbol;
  const tokenDecimals = supportedToken.decimals[parseInt(withdrawNetwork)];

  try {
    await provider.sendAsync({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
        },
      },
    });
    return "";
  } catch (error) {
    return error.message;
  }
}

export async function finalizeCrafter(
  web3,
  withdrawNetwork,
  tokenSymbol,
  sender,
  receiver,
  depositAmount,
  nonce,
  signature,
  signer,
  depositNetwork,
  txHash,
  setLoading
) {
  let chainData = getChainData();
  let toChain = chainData.supportedNetworks[withdrawNetwork];
  let finalizeType = null;

  if (toChain.isNativeSupported === true && toChain.symbol === tokenSymbol) {
    finalizeType = "finalizeNative";
  } else {
    finalizeType = "finalize";
  }

  let token = chainData.supportedTokens[tokenSymbol];

  let currentChainId = await web3.eth.getChainId();
  if (parseInt(withdrawNetwork) !== currentChainId) {
    return "Network on your metamask and receiver network must match !";
  }

  try {
    let contract = await new web3.eth.Contract(abi, toChain.omlink);
    let depositDecimals = token.decimals[depositNetwork];
    let withdrawDecimals = token.decimals[withdrawNetwork];

    const BigNumber = require("bignumber.js");

    // Assuming depositAmount is in the source chain's decimals
    let depositAmount_ = new BigNumber(depositAmount);

    // Calculate the factor to adjust decimals
    let decimalDifference = depositDecimals - withdrawDecimals;

    if (decimalDifference > 0) {
      // If depositDecimals > withdrawDecimals, divide by 10^decimalDifference
      depositAmount_ = depositAmount_.dividedBy(new BigNumber(10).exponentiatedBy(decimalDifference));
    } else if (decimalDifference < 0) {
      // If depositDecimals < withdrawDecimals, multiply by 10^(-decimalDifference)
      depositAmount_ = depositAmount_.multipliedBy(new BigNumber(10).exponentiatedBy(-decimalDifference));
    }

    let adjustedAmountInWei = depositAmount_.toString(10);
    let currentGasPrice_ = 0;

    if (toChain.gasPrice === false) {
      currentGasPrice_ = await web3.eth.getGasPrice();
    } else {
      currentGasPrice_ = web3.utils.toWei(toChain.gasPrice.toString(), "wei");
    }
    let sentTx;
    if (finalizeType === "finalizeNative") {
      sentTx = await contract.methods
        .finalizeNative(
          toChain.chainId,
          sender,
          receiver,
          adjustedAmountInWei,
          nonce,
          signature,
          signer
        )
        .send({
          from: receiver,
          gasPrice: currentGasPrice_,
        })
        .on("transactionHash", function (hash_) {
          let linkStartedStatus = sendLinkStartedRequest(
            txHash,
            depositNetwork
          );
          if (linkStartedStatus === "error") {
            return "Error while getting tx hash, please contact us.";
          }
        });
    } else {
      sentTx = await contract.methods
        .finalize(
          toChain.chainId,
          token.contracts[toChain.chainId],
          sender,
          receiver,
          adjustedAmountInWei,
          nonce,
          signature,
          signer
        )
        .send({
          from: receiver,
          gasPrice: currentGasPrice_,
        })
        .on("transactionHash", function (hash_) {
          let linkStartedStatus = sendLinkStartedRequest(
            txHash,
            depositNetwork
          );
          if (linkStartedStatus === "error") {
            return "Error while getting tx hash, please contact us.";
          }
        });
    }
    if (sentTx.status === true) {
      let linkFinishedStatus = sendLinkFinishedRequest(txHash, depositNetwork);
      if (linkFinishedStatus === "error") {
        return "Error while getting tx hash, please contact us.";
      }
    } else {
      return "Error with the receipt, please contact us.";
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e);
    if (e.includes("not mined")) {
      return "";
    } else {
      return e.message;
    }
  }
  return "";
}
