import React from "react";
import styles from "./stakeCard.module.css";
import { ReactComponent as Logo } from "../../../assets/omcIcon.svg";
import { useNavigate } from "react-router-dom";

export default function StakeCard({ stake, i }) {
  const navigate = useNavigate();
  let dateTime = new Date(stake[1] * 1000);
  let date = dateTime.toLocaleDateString();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.horizontal}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.title}>OMC</div>
        </div>
        <div className={styles.horizontal}>
          <div className={styles.index}>#{i}</div>
          <div>
            <button
              className={styles.button}
              onClick={() => navigate("/stake-detail/" + i, { state: stake })}
            >
              Manage
            </button>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.text}>Amount</div>
          <div className={styles.value}>
            {parseFloat(stake[0] / 10 ** 18).toLocaleString("en", { minimumFractionDigits: 4 })} OMC
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.text}>Date</div>
          <div className={styles.value}>{date}</div>
        </div>
        <div className={styles.line}></div>
      </div>
    </div>
  );
}
