export function getChainData() {
  return {
    supportedNetworks: {
      1: {
        chainId: 1,
        chainIdHex: "0x1",
        name: "Ethereum",
        symbol: "ETH",
        nativeCurrency: "Ether",
        decimals: 18,
        rpcUrl: "https://main-light.eth.linkpool.io/",
        blockExplorerUrl: "https://etherscan.io",
        owner: "0xfc15830948FA1177815496E56Ac5f806214b75B2",
        omlink: "0xcDa8C9991f725fF4fa6369FBC0A4F1Ab51Eae354",
        supportedTokens: ["OMC","USDT"],
        isNativeSupported: false,
        logoUrl: "",
        gasPrice: false,
      },
      56: {
        chainId: 56,
        chainIdHex: "0x38",
        name: "BSC",
        symbol: "BNB",
        nativeCurrency: "BNB Coin",
        decimals: 18,
        rpcUrl: "https://binance.llamarpc.com",
        blockExplorerUrl: "https://bscscan.com",
        owner: "0xfc15830948FA1177815496E56Ac5f806214b75B2",
        omlink: "0xcDa8C9991f725fF4fa6369FBC0A4F1Ab51Eae354",
        supportedTokens: ["OMC","USDT"],
        isNativeSupported: false,
        logoUrl: "",
        gasPrice: false,
      },
      21816: {
        chainId: 21816,
        chainIdHex: "0x5538",
        name: "omchain",
        symbol: "OMC",
        nativeCurrency: "Omchain",
        decimals: 18,
        rpcUrl: "https://seed.omchain.io",
        blockExplorerUrl: "https://explorer.omchain.io",
        owner: "0xfc15830948FA1177815496E56Ac5f806214b75B2",
        omlink: "0xcDa8C9991f725fF4fa6369FBC0A4F1Ab51Eae354",
        supportedTokens: ["OMC","USDT"],
        isNativeSupported: true,
        logoUrl: "",
        gasPrice: 300000000000,
      },
      43114: {
        chainId: 43114,
        chainIdHex: "0xA86A",
        name: "Avalanche",
        symbol: "AVAX",
        nativeCurrency: "AVAX",
        decimals: 18,
        rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
        blockExplorerUrl: "https://snowtrace.io",
        owner: "0xfc15830948FA1177815496E56Ac5f806214b75B2",
        omlink: "0xcDa8C9991f725fF4fa6369FBC0A4F1Ab51Eae354",
        supportedTokens: ["OMC","USDT"],
        isNativeSupported: false,
        logoUrl: "",
        gasPrice: false,
      },
      137: {
        chainId: 137,
        chainIdHex: "0x89",
        name: "Polygon",
        symbol: "MATIC",
        nativeCurrency: "MATIC",
        decimals: 18,
        rpcUrl: "https://polygon-bor.publicnode.com",
        blockExplorerUrl: "https://polygonscan.com/",
        owner: "0xfc15830948FA1177815496E56Ac5f806214b75B2",
        omlink: "0xcDa8C9991f725fF4fa6369FBC0A4F1Ab51Eae354",
        supportedTokens: ["OMC","USDT"],
        isNativeSupported: false,
        logoUrl: "",
        gasPrice: false,
      },
    },
    supportedTokens: {
      OMC: {
        name: "Omchain",
        native: "1",
        contracts: {
          1: "0x1f926B439FAe4595E5951029D47616FeCa401B77",
          56: "0x1f926B439FAe4595E5951029D47616FeCa401B77",
          21816: "0x0000000000000000000000000000000000000000",
          43114: "0x1f926B439FAe4595E5951029D47616FeCa401B77",
          137: "0x1f926B439FAe4595E5951029D47616FeCa401B77",
        },
        decimals: {
          1: 18,
          56: 18,
          21816: 18,
          43114: 18,
          137: 18,
        }
      },
      USDT: {
        name: "Tether USD",
        native: "0",
        contracts: {
          1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
          56: "0x55d398326f99059fF775485246999027B3197955",
          21816: "0xe0796AaF4057603898D2570A6453C9d1Af5BEeB4",
          43114: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
          137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        },
        decimals: {
          1: 6,
          56: 18,
          21816: 18,
          43114: 6,
          137: 6,
        }
      }
    },
  };
}