import React from "react";
import Header from "../../components/HomeComponents/Header/Header";
import Form from "../../components/BridgeComponents/Form/Form";

export default function Bridge({walletConnect}) {
  return (
    <div>
      <Header text={"Easy Asset Transfer Between Networks"} />
      <Form walletConnect={walletConnect} />
    </div>
  );
}
