import React, { useEffect, useState } from "react";
import styles from "./stake.module.css";
import axios from "axios";

export default function OmstakeCard() {
  const [linked, setLinked] = useState();
  useEffect(() => {
    axios
      .get(
        "https://explorer.omchain.io/api?module=account&action=eth_get_balance&address=0x12EDDca44BF5C919768c69aD9BDE9D05d1d5d94d"
      )
      .then((res) =>
        setLinked(parseInt(res.data.result / 1000000000000000000))
      );
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.logo}>Stake</div>
      <div className={styles.textContainer}>
        {linked !== undefined ? (
          <div className={styles.title}>{linked.toLocaleString("en")}</div>
        ) : (
          "--"
        )}
        <div className={styles.text}>Total Stake</div>
      </div>
    </div>
  );
}
