import React, { useState } from "react";
import styles from "./modal.module.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Back } from "../../../assets/arrowLeft.svg";
import { safeTransferFrom } from "../../../utils/NftFunctions/NftFunctions";

export default function TranferModal({
  show,
  setShow,
  tokenId,
  walletAddress,
}) {
  const [loading, setLoading] = useState(false);
  const [recWalletAddress, setRecWalletAddress] = useState("");

  async function handleAccept() {
    setLoading(true);
    try {
      await safeTransferFrom(
        walletAddress,
        recWalletAddress,
        tokenId
      );
      setLoading(false);
      setShow(false);
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={show}
      onHide={!show}
      animation={true}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div onClick={() => setShow(false)} className={styles.back}>
          <Back />
        </div>
        <div className={styles.title}>NFT Transfer</div>
        <div className={styles.messageContainer}>
          <input
            className={styles.input}
            onChange={(e) => setRecWalletAddress(e.target.value)}
            placeholder="Enter wallet address"
          />
        </div>
        <div className={styles.buttonContainer}>
          <div>
            <button className={styles.cancel} onClick={() => setShow(false)}>
              Cancel
            </button>
          </div>
          <div>
            <button className={styles.accept} onClick={handleAccept}>
              {" "}
              {loading ? (
                <div className={styles.shimmer}>Waiting response...</div>
              ) : (
                "Transfer"
              )}
            </button>{" "}
          </div>
        </div>
      </div>
    </Modal>
  );
}
