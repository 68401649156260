import React from "react";
import styles from "./wallet.module.css";

export default function NftWallet({ detail }) {
  return (
    <div className={styles.container}>
      <div className={styles.contentOne}>
        <div className={styles.cardTitle}>Add NFT to Metamask</div>
        <div className={styles.step}>
          <div className={styles.titleS}>Step 1:</div>
          <div className={styles.cardText}>Open the Metamask Mobile App</div>
        </div>
        <div className={styles.step}>
          <div className={styles.titleS}>Step 2:</div>
          <div className={styles.cardText}>Select the NFT tab</div>
        </div>
        <div className={styles.step}>
          <div className={styles.titleS}>Step 3:</div>
          <div className={styles.cardText}>Click the import NFT button</div>
        </div>
        <div className={styles.step}>
          <div className={styles.titleS}>Step 4:</div>
          <div className={styles.cardText}>
            Add contract address and token id
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.titleS}>Contract Address:</div>
          <div className={styles.titleSm}>
            {detail && detail.contract_address}
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.titleS}>Token ID:</div>
          <div className={styles.titleSm}>{detail && detail.token_id}</div>
        </div>
      </div>
    </div>
  );
}
