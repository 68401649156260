import React, { useState, useContext } from "react";
import styles from "./enter.module.css";
import { Web3Context } from "../../../utils/Web3Context";
import { AppContext } from "../../../utils/Context";
import WrongNetworkModal from "../../StakeComponents/WrongNetworkModal/WrongNetworkModal";

export default function EnterAmount({ connectionStatus }) {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showWrongModal, setShowWrongModal] = useState(false);
  const amountValid = new RegExp("^[\\.0-9]*$");
  const web3 = useContext(Web3Context).web3;
  const account = useContext(AppContext).account;
  const chainId = useContext(AppContext).currentChainId;
  const walletConnect = useContext(Web3Context).walletConnect;
  let balance = useContext(AppContext).accountBalance;

  const onAmountChange = (e) => {
    let enteredAmount = e.target.value;
    if (!amountValid.test(enteredAmount)) {
      setMessage("Invalid amount");
      setAmount("");
    } else {
      setMessage("");
      if (parseFloat(enteredAmount) > parseFloat(balance)) {
        setMessage("The amount entered is not in your wallet.");
        setAmount("");
      } else {
        setMessage("");
        setAmount(enteredAmount);
      }
    }
  };

  const submitBurn = () => {
    if (
      parseInt(chainId) !==
      parseInt(process.env.REACT_APP_STAKE_CORRECT_CHAIN_ID)
    ) {
      setShowWrongModal(true);
    } else {
      setShowWrongModal(false);
      if (amount !== "") {
        setLoading(true);
        web3.eth
          .sendTransaction({
            from: account,
            to: "0x0000000000000000000000000000000000000000",
            value: web3.utils.toWei(amount.toString(), 'ether'),
          })
          .then(function () {
            setLoading(false);
            window.location.reload();
          })
          .catch(() => {
            setMessage("Something went wrong. Please try again.");
            setLoading(false);
          });
      }
    }
  };
  return (
    <div className={styles.container}>
      <div>
        <input
          className={styles.input}
          onChange={onAmountChange}
          placeholder="Enter amount"
        />
      </div>
      <div>
        {connectionStatus ? (
          <button
            className={styles.button}
            onClick={submitBurn}
            disabled={loading}
          >
            {loading ? (
              <div className={styles.shimmer}>Waiting response...</div>
            ) : (
              "Burn"
            )}
          </button>
        ) : (
          <button className={styles.button} onClick={walletConnect}>
            Connect Wallet
          </button>
        )}
      </div>
      <div className={styles.alert}>{message}</div>
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
