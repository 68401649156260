import React from "react";
import styles from "./parent.module.css";
import { ReactComponent as Comment } from "../../assets/comment.svg";
import { createIcon } from "../../utils/WallFunctions/Blockies";


export default function ParentMessage({parent}) {
  function generateAvatar(seed) {
    var canvas = createIcon({ seed, size: 10, scale: 4.7 });
    return canvas.toDataURL();
  }
  return (
    <div className={styles.messageList}>
      <div className={styles.container}>
        <div className={styles.logo}>
        <img src={generateAvatar(parent.sender_address)} alt="" />
        </div>
        <div className={styles.messageInfo}>
          <div className={styles.user}>
            <div className={styles.username}>{parent.owner}</div>
            <div className={styles.address}>{parent.sender_address.substr(0, 6)}</div>
          </div>
          <div className={styles.message}>{parent.message}</div>
        </div>
        <div className={styles.comments}>
          <div>
            <Comment />
          </div>
          <div className={styles.count}>{parent.child_message_count}</div>
        </div>
      </div>
    </div>
  );
}
