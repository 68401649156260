import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/HomeComponents/Header/Header";
import Form from "../../components/CreateNft/CreateNftForm/Form";
import { AppContext } from "../../utils/Context";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";
import styles from "./createNft.module.css";

export default function CreateNft() {
  const connectionStatus = useContext(AppContext).connectionStatus;
  const isCorrectNetwork = useContext(AppContext).isCorrectNetwork;
  const [showWrongModal, setShowWrongModal] = useState(false);

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetwork) {
        setShowWrongModal(false);
      } else {
        setShowWrongModal(true);
      }
    }
  }, [connectionStatus, isCorrectNetwork]);

  return (
    <div className={styles.container}>
      {" "}
      <Header text={"Create New Item"} />
      <Form />
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
