import React from "react";
import OmcValue from "../../components/HomeComponents/OmcValue/OmcValue";
import styles from "./home.module.css";
import CirculatingSupply from "../../components/HomeComponents/CirculatingSupply/CirculatingSupply";
import OmlinkCard from "../../components/HomeComponents/OmlinkCard/OmlinkCard";
import OmstakeCard from "../../components/HomeComponents/OmstakeCard/OmstakeCard";
import WriteContainer from "../../components/WallComponents/WriteContainer";
import { useContext } from "react";
import { AppContext } from "../../utils/Context";
import BalancesCard from "../../components/HomeComponents/BalancesCard/BalancesCard";
import OmcBalancesCard from "../../components/HomeComponents/BalancesCard/omcBalances";
import MessageContainer from "../../components/WallComponents/MessageContainer";
import { useNavigate } from "react-router-dom";
import SwitchButton from "../../components/HomeComponents/SwitchButton/SwitchButton";
import AddOmcButton from "../../components/HomeComponents/AddOmcButton/AddOmcButton";
import StakeBalances from "../../components/HomeComponents/BalancesCard/StakeBalances";

export default function Home({ userStakeAmount }) {
  const isConnected = useContext(AppContext);
  const chainId = useContext(AppContext).currentChainId;
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <OmcValue />
      <div
        className={
          isConnected.connectionStatus
            ? `${styles.cardContainerOrdered}`
            : `${styles.cardContainer}`
        }
      >
        <div>
          <CirculatingSupply />
        </div>
        <div className={styles.vertical}>
          <OmlinkCard />
          <OmstakeCard />
        </div>
      </div>

      {isConnected.connectionStatus && (
        <>
          <div className={styles.cards}>
            <OmcBalancesCard />
            <BalancesCard />
          </div>{" "}
          {parseInt(chainId) ===
            parseInt(process.env.REACT_APP_STAKE_CORRECT_CHAIN_ID) && (
            <StakeBalances userStakeAmount={userStakeAmount} />
          )}
          <div className={styles.buttonGroup}>
            <SwitchButton />
            {parseInt(chainId) !==
            parseInt(process.env.REACT_APP_STAKE_CORRECT_CHAIN_ID) ? (
              <AddOmcButton />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <div className={styles.wallContainer}>
        <WriteContainer />
        <div className={styles.messageList}>
          <MessageContainer page="home" />
          <div className={styles.more} onClick={() => navigate("/wall")}>
            More
          </div>
        </div>
      </div>
      <div className={styles.purchaseContainer}></div>
    </div>
  );
}
