import React, { useState } from "react";
import styles from "./props.module.css";
import { ReactComponent as DescIcon } from "../../../assets/description-icon.svg";
import { ReactComponent as Down } from "../../../assets/down.svg";
import { ReactComponent as Up } from "../../../assets/up.svg";
import { ReactComponent as Prop } from "../../../assets/prop.svg";
import { ReactComponent as About } from "../../../assets/about.svg";
import { ReactComponent as Detail } from "../../../assets/detail.svg";
import { ReactComponent as LinkIcon } from "../../../assets/link_icon.svg";

export default function NftProps({ detail }) {
  const [showProps, setShowProps] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <div className={styles.icon}>
            <DescIcon />
          </div>
          <div>Description</div>
        </div>
        <div className={styles.itemText}>{detail && detail.description}</div>
      </div>

      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <div className={styles.icon}>
            <LinkIcon />
          </div>
          <div>External Link</div>
        </div>
        <div className={styles.itemText}>{detail && detail.external_link}</div>
      </div>

      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <div className={styles.icon}>
            <Prop />
          </div>
          <div>Properties</div>
          <div
            className={styles.button}
            onClick={() => setShowProps(!showProps)}
          >
            {showProps ? <Up /> : <Down />}
          </div>
        </div>
        {showProps && (
          <div className={styles.props}>
            {detail &&
              detail.properties.length > 0 &&
              detail.properties.map((data, i) => {
                return (
                  <div key={i} className={styles.propCard}>
                    <div className={styles.propCardTitle}>
                      {data.trait_type}
                    </div>
                    <div className={styles.propCardText}>{data.value}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <div className={styles.icon}>
            <About />
          </div>
          <div>Levels</div>
          <div
            className={styles.button}
            onClick={() => setShowAbout(!showAbout)}
          >
            {showAbout ? <Up /> : <Down />}
          </div>
        </div>
        {showAbout && (
          <div className={styles.props}>
            {detail &&
              detail.levels.length > 0 &&
              detail.levels.map((data, i) => {
                return (
                  <div key={i} className={styles.propCard}>
                    <div className={styles.propCardTitle}>
                      {data.trait_type}
                    </div>
                    <div className={styles.propCardText}>{data.value}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <div className={styles.icon}>
            <Detail />
          </div>
          <div>Stats</div>
          <div
            className={styles.button}
            onClick={() => setShowDetail(!showDetail)}
          >
            {showDetail ? <Up /> : <Down />}
          </div>
        </div>
        {showDetail && (
          <div className={styles.props}>
            {detail &&
              detail.stats.length > 0 &&
              detail.stats.map((data, i) => {
                return (
                  <div key={i} className={styles.propCard}>
                    <div className={styles.propCardTitle}>
                      {data.trait_type}
                    </div>
                    <div className={styles.propCardText}>{data.value}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
