import { getChainData } from "./ChainData";
import {
  actionAllowance,
  actionApprove,
  depositCrafter,
} from "./WalletFunctions";

export function checkObjEqual(obj1, obj2) {
  for (let key in obj1) {
    if (!(key in obj2)) return false;
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
}

function getArraysIntersection(a1, a2) {
  return a1.filter(function (n) {
    return a2.indexOf(n) !== -1;
  });
}

export async function getTokenList(depositNetwork, withdrawNetwork) {
  let supportedNetworks = [];
  let tokenList = [];
  let depositTokenList = [];
  let withdrawTokenList = [];
  supportedNetworks = getChainData().supportedNetworks;
  Object.keys(supportedNetworks).forEach((key, i) => {
    if (depositNetwork === key) {
      depositTokenList.push(supportedNetworks[key].supportedTokens);
    }
    if (withdrawNetwork === key) {
      withdrawTokenList.push(supportedNetworks[key].supportedTokens);
    }
  });  
  tokenList = getArraysIntersection(depositTokenList[0], withdrawTokenList[0]);
  return tokenList;
}

export async function callAllowanceAction(
  enteredDepositAmount,
  depositNetwork,
  tokenSymbol,
  web3,
  account
) {
  let chainData = getChainData();
  let tempAllowanceStatus;
  if (enteredDepositAmount === 0) {
    return;
  } else {
    if (
      chainData.supportedNetworks[parseInt(depositNetwork)]
        .isNativeSupported === true &&
      tokenSymbol === chainData.supportedNetworks[depositNetwork].symbol
    ) {
      return;
    } else {
      tempAllowanceStatus = await actionAllowance(
        web3,
        chainData.supportedTokens[tokenSymbol].contracts[depositNetwork],
        account,
        chainData.supportedNetworks[depositNetwork].omlink,
        enteredDepositAmount,
        chainData.supportedTokens[tokenSymbol].decimals[depositNetwork],
      );
    }
  }
  return tempAllowanceStatus;
}

export async function callApproveAciton(
  web3,
  depositNetwork,
  account,
  allowanceStatus,
  tokenSymbol
) {
  let chainData = getChainData();
  if (!allowanceStatus) {
    let status = await actionApprove(
      web3,
      chainData.supportedTokens[tokenSymbol].contracts[depositNetwork],
      account,
      chainData.supportedNetworks[depositNetwork].omlink,
      chainData.supportedNetworks[depositNetwork].gasPrice
    );
    if (status === false) {
      return "Approve cancelled.";
    } else {
      return true;
    }
  } else {
    return "You already approved.";
  }
}

export async function callDepositAction(
  web3,
  withdrawNetwork,
  depositNetwork,
  tokenSymbol,
  account,
  sender,
  depositAmount
) {
  let depositErrorMsg = await depositCrafter(
    web3,
    withdrawNetwork,
    depositNetwork,
    tokenSymbol,
    account,
    sender,
    depositAmount
  );

  return depositErrorMsg;
}
