import React from "react";
import styles from "./unstake.module.css";

export default function UnstakeButton({setShowUnstakeModal}) {
  return (
    <div>
      <button className={styles.button} onClick={()=>setShowUnstakeModal(true)}>Unstake</button>
    </div>
  );
}
