import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/HomeComponents/Header/Header";
import styles from "./browse.module.css";
import NftCard from "../../components/BrowseNFT/NftCard/NftCard";
import axios from "axios";
import { AppContext } from "../../utils/Context";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";
import Pagination from "../../components/BrowseNFT/Pagination/Pagination";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export default function BrowseNft() {
  const [nftData, setNftData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showWrongModal, setShowWrongModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItem = nftData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const isConnected = useContext(AppContext);
  const isCorrectNetwork = useContext(AppContext).isCorrectNetwork;
  const connectionStatus = useContext(AppContext).connectionStatus;

  const browseNft = () => {
    setLoading(true);
    if (!isConnected.connectedAccount) {
      return;
    }
    api
      .get(`nft-minter/query/${isConnected.connectedAccount}`)
      .then((res) => {
        setNftData(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetwork) {
        setShowWrongModal(false);
        browseNft();
      } else {
        setShowWrongModal(true);
      }
    }
  // eslint-disable-next-line
  }, [connectionStatus, isCorrectNetwork]);

  return (
    <div className={styles.container}>
      {" "}
      <Header text={"Browse your NFT's"} />
      <div className={styles.list}>
        {loading ? (
          <div className={styles.shimmer}>Loading...</div>
        ) : nftData.length === 0 ? (
          <div className={styles.empty}>NFT not found.</div>
        ) : (
          currentItem.map((data, i) => {
            return (
              <NftCard
                key={i}
                name={data.name}
                image={data.image}
                id={data.id}
                tokenId={data.token_id}
                contractAddress={data.contract_address}
              />
            );
          })
        )}
      </div>
      {nftData.length > 0 && isConnected.connectionStatus && (
        <Pagination
          itemPerPage={itemPerPage}
          totalItem={nftData.length}
          paginate={paginate}
        />
      )}
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
