import React from "react";
import styles from "./wrong.module.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { Web3Context } from "../../../utils/Web3Context";

export default function WrongNetworkModal({
  showWrongModal,
  setShowWrongModal,
}) {
  const provider = useContext(Web3Context).provider;
  const walletConnect = useContext(Web3Context).walletConnect;

  async function switchNetwork() {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID_AS_HEX }],
      });
      setShowWrongModal(false);
      window.location.reload();
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: process.env.REACT_APP_CHAIN_ID_AS_HEX,
                chainName: process.env.REACT_APP_NETWORK_NAME,
                nativeCurrency: {
                  name: process.env.REACT_APP_TOKEN_NAME,
                  symbol: process.env.REACT_APP_SYMBOL,
                  decimals: 18,
                },
                rpcUrls: [process.env.REACT_APP_RPC_URL],
              },
            ],
          });
          setShowWrongModal(false);
          walletConnect();
        } catch (addError) {}
      }
    }
  }
  return (
    <Modal
      show={showWrongModal}
      onHide={!showWrongModal}
      animation={true}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.text}>
          You are connected to the wrong network, please switch to the omchain
          network
        </div>
        <div>
          <button className={styles.button} onClick={switchNetwork}>
            Switch to the omchain
          </button>
        </div>
      </div>
    </Modal>
  );
}
