import React, { useEffect, useState } from "react";
import WriteContainer from "../../components/WallComponents/WriteContainer";
import styles from "./wall.module.css";
import ParentMessage from "../../components/WallComponents/ParentMessage";
import ChildPosts from "../../components/WallComponents/ChildPosts";
import { useParams } from "react-router-dom";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_WALL,
});

export default function Post() {
  let { id } = useParams();
  const [parentMessage, setParentMessage] = useState();
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    api
      .get("/get-detail/" + id)
      .then((res) => {
        setParentMessage(res.data.data.parent_message);
        setReplies(res.data.data.child_messages);
      })
      .catch((e) => console.error(e));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {parentMessage && replies && (
        <div>
          <ParentMessage parent={parentMessage} />
          <div className={styles.write}>
            <WriteContainer isPage={"detail"} txHash={parentMessage.tx_hash} />
          </div>
          <div className={styles.message}>
            <ChildPosts replies={replies} />
          </div>
        </div>
      )}
    </div>
  );
}
