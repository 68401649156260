import React from "react";
import styles from "./ticket.module.css";
import TicketTimer from "../TicketTimer";
import bscLogo from "../../../assets/bscLogo.png";
import ethLogo from "../../../assets/ethLogo.png";
import omcLogo from "../../../assets/omcLogo.png";
import avaxLogo from "../../../assets/avaxLogo.png";
import maticLogo from "../../../assets/maticLogo.png";

export default function TicketStatus({ status, depositObject }) {
  let waiting = true;
  let pending = false;
  let generated = false;

  if (
    status === 1 ||
    status === 2 ||
    status === 3 ||
    status === 4
  ) {
    pending = true;
  }

  if (
    status === 2 ||
    status === 3 ||
    status === 4
  ) {
    generated = true;
  }

  return (
    <div className={styles.container}>
      <div className={styles.networkContainer}>
        <div className={styles.networkLogo}>
          <img
            className={styles.networkLogo}
            src={
              depositObject.deposit_network === 21816
                ? omcLogo
                : depositObject.deposit_network === 1
                ? ethLogo
                : depositObject.deposit_network === 56
                ? bscLogo
                : depositObject.deposit_network === 137
                ? maticLogo
                : avaxLogo
            }
            alt="logo"
          />
        </div>
        <div className={styles.networkText}>Sender Network</div>
      </div>
      <div className={styles.timerContainer}>
        <div className={styles.timerText}>Timer</div>
        <div className={styles.time}>
          <TicketTimer
            status={status}
            confirmedTime={depositObject.deposit_confirmed_time}
          />
        </div>
      </div>
      <div className={styles.statusContainer}>
        <div className={styles.iconContainer}>
          <div
            className={
              waiting ? `${styles.statusIconActive}` : `${styles.statusIcon}`
            }
          ></div>
          <div
            className={waiting ? `${styles.lineActive}` : `${styles.line}`}
          ></div>
          <div
            className={
              pending ? `${styles.statusIconActive}` : `${styles.statusIcon}`
            }
          ></div>
          <div
            className={
              pending ? `${styles.lineTwoActive}` : `${styles.lineTwo}`
            }
          ></div>
          <div
            className={
              generated ? `${styles.statusIconActive}` : `${styles.statusIcon}`
            }
          ></div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.statusText}>Waiting Verification</div>
          <div className={styles.statusText}>Ticket Pending</div>
          <div className={styles.statusText}>Ticket Created</div>
        </div>
      </div>
    </div>
  );
}
