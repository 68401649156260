import React, { useContext, useState } from "react";
import styles from "./receive.module.css";
import { Web3Context } from "../../../utils/Web3Context";
import { AppContext } from "../../../utils/Context";
import { finalizeCrafter } from "../../../utils/BridgeFunctions/WalletFunctions";

export default function ReceiveButton({
  depositObject,
  ticketObject,
  setMessage,
}) {
  const web3 = useContext(Web3Context).web3;
  const account = useContext(AppContext).account;
  const [loading, setLoading] = useState(false);

  async function callFinalizeAction() {
    setLoading(true);
    if (ticketObject) {
      let errorMsg = await finalizeCrafter(
        web3,
        depositObject.withdraw_network,
        depositObject.token_symbol,
        account,
        account,
        depositObject.deposit_amount,
        ticketObject.nonce,
        ticketObject.signature,
        ticketObject.signer,
        depositObject.deposit_network,
        depositObject.tx_hash,
        setLoading
      );
      if (errorMsg !== "") {
        setMessage(errorMsg);
        setTimeout(() => {
          setMessage("");
        }, 5000);
        setLoading(false);
      }
    } else {
      setMessage("Wait until your ticket is created.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
    }
  }
  return (
    <div>
      {depositObject.status === 4 ? (
        <button className={styles.disabledButton} disabled={true}>
          Receive
        </button>
      ) : (
        <button className={styles.button} onClick={callFinalizeAction}>
          {loading ? (
            <div className={styles.shimmer}>Waiting response...</div>
          ) : (
            "Receive"
          )}
        </button>
      )}
    </div>
  );
}
