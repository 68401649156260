import React, { useContext } from "react";
import styles from "./addOmc.module.css";
import { Web3Context } from "../../../utils/Web3Context";

export default function AddOmcButton() {
  const provider = useContext(Web3Context).provider;

  async function actionAddToken() {
    try {
      await provider.sendAsync({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x1f926B439FAe4595E5951029D47616FeCa401B77",
            symbol: "OMC",
            decimals: "18",
          },
        },
      });
    } catch (error) {
      return error.message;
    }
  }

  return (
    <button className={styles.container} onClick={actionAddToken}>
      <div>Add OMC to Metamask</div>
    </button>
  );
}
