import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_BRIDGE,
});

export function sendDepositCreatedRequest(
  txHash,
  sender,
  receiver,
  depositContract,
  tokenSymbol,
  withdrawNetwork,
  depositNetwork,
  depositAmount,
  withdrawContract
) {
  api
    .post("/deposit/create/", {
      txHash: txHash,
      sender: sender,
      receiver: receiver,
      depositContract: depositContract,
      withdrawNetwork: withdrawNetwork,
      depositNetwork: depositNetwork,
      depositAmount: depositAmount,
      withdrawContract: withdrawContract,
      tokenSymbol: tokenSymbol,
    })
    .then(function (response) {
      if (response.data.error === true) {
        return "error";
      } else {
        if (response.data.data === "OK") {
          window.location = "/status/" + depositNetwork + "/" + txHash;
          return "success";
        } else {
          return "error";
        }
      }
    })
    .catch(function () {
      return "error";
    });
}

export function sendLinkStartedRequest(txHash, depositNetwork) {
  api
    .post("/link/started/", {
      txHash: txHash,
      depositNetwork: depositNetwork,
    })
    .then(function (response) {
      if (response.data.error === true) {
        return "error";
      }
    })
    .catch(function () {
      return "error";
    });
}

export function sendLinkFinishedRequest(txHash, depositNetwork) {
  api
    .post("/link/finished/", {
      txHash: txHash,
      depositNetwork: depositNetwork,
    })
    .then(function (response) {
      if (response.data.error === true) {
        return "error";
      }
    })
    .catch(function () {
      return "error";
    });
}
