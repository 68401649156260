import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import styles from "./detail.module.css";
import BalanceContainer from "../../components/StakeComponents/BalanceContainer/BalanceContainer";
import AmountCard from "../../components/StakeComponents/AmountCard/AmountCard";
import DateContainer from "../../components/StakeComponents/DateContainer/DateContainer";
import UnstakeButton from "../../components/StakeComponents/UnstakeButton/UnstakeButton";
import UnstakeModal from "../../components/StakeComponents/UnstakeModal/UnstakeModal";
import {
  convertAtomicToReadable,
} from "../../utils/StakeFunctions/Conversion";
import { AppContext } from "../../utils/Context";
import { getEarned } from "../../utils/StakeFunctions/StakeV2Functions";

export default function StakeDetail({getUserStakes}) {
  const connectionStatus = useContext(AppContext).connectionStatus;
  const isCorrectNetworkForStake =
    useContext(AppContext).isCorrectNetworkForStake;

  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [showCompoundModal, setShowCompoundModal] = useState(false);
  const [showPenaltyButton, setShowPenaltyButton] = useState(false);
  const [userReward, setUserReward] = useState(0);
  const index = useParams().index;
  const stake = useLocation().state;
  const stakeAmount = convertAtomicToReadable(stake[0]);
  const stakeDate = stake[1];
  const stakeEnds = stake[2];
  const [isLoading, setIsLoading] = useState(true);

  let dateTime = new Date(stakeDate * 1000);
  let date = dateTime.toLocaleDateString();

  useEffect(() => {
    if (!connectionStatus || !isCorrectNetworkForStake) {
      return;
    }
    setIsLoading(false);
  }, [connectionStatus, isCorrectNetworkForStake]);

  getEarned(index).then((res) => {
    setUserReward(convertAtomicToReadable(res));
  });

  return (
    isLoading ? (
      <div className={styles.loading}></div>
    ) : (
    <div>
      <BalanceContainer />
      <div className={styles.amountContainer}>
        <AmountCard
          type="stake"
          title={"Stake Amount"}
          amount={(stakeAmount).toLocaleString("en", { minimumFractionDigits: 2 })}
        />
        <AmountCard
          type="reward"
          title={"Reward Amount"}
          amount={(userReward).toFixed(2)}
        />
      </div>
      <div className={styles.calendarContainer}>
        <DateContainer title={"Start date :"} date={date} />
        <DateContainer
          title={"Unpunished date :"}
          date={new Date(stakeEnds * 1000).toLocaleDateString()}
        />
      </div>
      {new Date(stakeEnds * 1000) < new Date() ? (
        <div className={styles.buttonContainer}>
          <UnstakeButton setShowUnstakeModal={setShowUnstakeModal} />
        </div>
      ) : (
        <div className={styles.buttonContainer}>
        </div>
      )}
      <UnstakeModal
        showUnstakeModal={showUnstakeModal}
        setShowUnstakeModal={setShowUnstakeModal}
        index={index}
        userReward={userReward}
        getUserStakes={getUserStakes}
      />
    </div>
    )
  );
}
