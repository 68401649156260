import React, { useState, useContext, useEffect } from "react";
import styles from "./form.module.css";
import { ReactComponent as File } from "../../../assets/file.svg";
import { ReactComponent as List } from "../../../assets/list.svg";
import { ReactComponent as Plus } from "../../../assets/plus.svg";
import { ReactComponent as Star } from "../../../assets/star.svg";
import { ReactComponent as Graph } from "../../../assets/graph.svg";
import PropertiesModal from "../PropertiesModal/PropertiesModal";
import LevelsModal from "../LevelsModal/LevelsModal";
import StatsModal from "../StatsModal/StatsModal";
import axios from "axios";
import { AppContext } from "../../../utils/Context";
import { signMint } from "../../../utils/NftFunctions/NftFunctions";
import { useNavigate } from "react-router-dom";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export default function Form() {
  const navigate = useNavigate();
  const [showPropModal, setShowPropModal] = useState(false);
  const [showLevelsModal, setShowLevelsModal] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const isConnected = useContext(AppContext);

  const [file, setFile] = useState(null);

  const [nftName, setNftName] = useState("");
  const [link, setLink] = useState("");
  const [desc, setDesc] = useState("");
  const [props, setProps] = useState([]);
  const [levels, setLevels] = useState([]);
  const [stats, setStats] = useState([]);
  const [supply, setSupply] = useState(1);
  const [chain, setChain] = useState("omchain");

  const [type, setType] = useState([]);
  const [name, setName] = useState([]);

  const [levelName, setLevelName] = useState([]);
  const [levelMaxValue, setLevelMaxValue] = useState([]);
  const [levelMinValue, setLevelMinValue] = useState([]);

  const [statName, setStatName] = useState([]);
  const [statMaxValue, setStatMaxValue] = useState([]);
  const [statMinValue, setStatMinValue] = useState([]);

  const [propsHelper, setPropsHelper] = useState(false);
  const [levelsHelper, setLevelsHelper] = useState(false);
  const [statsHelper, setStatsHelper] = useState(false);

  const [itemCountProps, setItemCountProps] = useState(1);
  const [itemCountLevels, setItemCountLevels] = useState(1);
  const [itemCountStats, setItemCountStats] = useState(1);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const [fileMessage, setFileMessage] = useState("");

  const [myself, setMyself] = useState(false);

  const formData = new FormData();

  let tempProps = [];
  let tempStats = [];
  let tempLevels = [];

  const uploadFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files.length !== 0) {
      setSelectedFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    if (file !== null) {
      const MAX_FILE_SIZE = 10240; // 10MB
      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setFileMessage("The file size can be up to 10 MB!");
        return;
      } else {
        setFileMessage("");
      }
    }
  }, [file]);

  useEffect(() => {
    for (let i = 0; i < type.length; i++) {
      tempProps.push({ type: type[i], name: name[i] });
    }
  // eslint-disable-next-line
  }, [propsHelper]);

  useEffect(() => {
    setProps(tempProps);
  // eslint-disable-next-line
  }, [propsHelper]);

  useEffect(() => {
    for (let i = 0; i < levelName.length; i++) {
      tempLevels.push({
        name: levelName[i],
        maxValue: levelMaxValue[i],
        minValue: levelMinValue[i],
      });
    }
  // eslint-disable-next-line
  }, [levelsHelper]);

  useEffect(() => {
    setLevels(tempLevels);
  // eslint-disable-next-line
  }, [levelsHelper]);

  useEffect(() => {
    for (let i = 0; i < statName.length; i++) {
      tempStats.push({
        name: statName[i],
        maxValue: statMaxValue[i],
        minValue: statMinValue[i],
      });
    }
  // eslint-disable-next-line
  }, [statsHelper]);

  useEffect(() => {
    setStats(tempStats);
  // eslint-disable-next-line
  }, [statsHelper]);

  async function signMintAction(result, tokenIds) {
    let status = await signMint(result, tokenIds);
    if (status === true) {
      clearInputs();
      setMessage({ type: "success", text: "Your nft is minted!" });
      navigate("/browse-nft");
    } else {
      setMessage({ type: "error", text: "Something wrong, please try again!" });
    }
  }

  const clearInputs = () => {
    setSelectedFile(null);
    setNftName("");
    setChain("");
    setLink("");
    setDesc("");
    setFile(null);
    setSupply("");
    setProps([]);
    setLevels([]);
    setStats([]);
    setName([]);
    setType([]);
    setLevelName([]);
    setLevelMaxValue([]);
    setLevelMinValue([]);
    setStatName([]);
    setStatMaxValue([]);
    setStatMinValue([]);
    setItemCountProps(1);
    setItemCountLevels(1);
    setItemCountStats(1);
    setLoading(false);
    setMyself(false);
  };

  const createNft = () => {
    if (fileMessage === "") {
      setLoading(true);
      formData.append("nftImage", file);
      formData.append("nftName", nftName);
      formData.append("nftExternalLink", link);
      formData.append("nftDescription", desc);
      formData.append("nftProperties", JSON.stringify(props));
      formData.append("nftStats", JSON.stringify(stats));
      formData.append("nftLevels", JSON.stringify(levels));
      formData.append("nftSupply", supply);
      formData.append("nftChain", chain);
      formData.append("walletAddress", isConnected.connectedAccount);
      formData.append("mintMyself", myself);
      api
        .post("nft-minter/mint", formData)
        .then((res) => {
          if (myself) {
            signMintAction(res.data.data.result, res.data.data.tokenIds);
          } else {
            clearInputs();
            setMessage({ type: "success", text: "Your nft is minted!" });
            setInterval(() => {
              setMessage({ type: "", text: "" });
            }, 5000);
            navigate("/browse-nft");
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          if (file === null || nftName === "") {
            setMessage({
              type: "error",
              text: "File and nft name cannot be empty",
            });
          } else {
            setMessage({
              type: "error",
              text: "Something went wrong, please try again",
            });
          }
          setInterval(() => {
            setMessage({ type: "", text: "" });
          }, 5000);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Image, Video, Audio, or 3D Model <span>*</span>
      </div>
      <div className={styles.sm}>
        File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB,
        GLTF. Max size: 10 MB
      </div>
      <label htmlFor="upload" className={styles.file}>
        <input
          id="upload"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => uploadFile(e)}
        />
        {selectedFile ? (
          <img className={styles.preview} src={selectedFile} alt=""></img>
        ) : (
          <File />
        )}
      </label>
      <div className={styles.alert}>{fileMessage}</div>
      <div className={styles.text}>
        Name <span>*</span>
      </div>
      <div>
        <input
          className={styles.input}
          placeholder="Item name"
          value={nftName}
          onChange={(e) => setNftName(e.target.value)}
        />
      </div>
      <div className={styles.text}>External Link</div>
      <div className={styles.sm}>
        You are welcome to link to your own webpage with more details.
      </div>
      <div>
        <input
          className={styles.input}
          value={link}
          placeholder="https://yoursite.io/item/123"
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
      <div className={styles.text}>Description</div>
      <div className={styles.sm}>
        The description will be included on the item's detail page underneath
        its image. Markdown syntax is supported.{" "}
      </div>
      <div>
        <textarea
          className={styles.textarea}
          rows={4}
          value={desc}
          placeholder="Provide a detailed description of your item."
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      <div className={styles.property}>
        <div>
          <div className={styles.horizontal}>
            <div className={styles.icon}>
              <List />
            </div>
            <div className={styles.pTitle}>Properties</div>
          </div>
          <div className={styles.pText}>
            Textual traits that show up as rectangles
          </div>
        </div>
        <div className={styles.pButton}>
          <button onClick={() => setShowPropModal(true)}>
            <Plus />
          </button>
        </div>
      </div>
      <div className={styles.property}>
        <div>
          <div className={styles.horizontal}>
            <div className={styles.icon}>
              <Star />
            </div>
            <div className={styles.pTitle}>Levels</div>
          </div>
          <div className={styles.pText}>
            Numerical traits that show as a progress bar
          </div>
        </div>
        <div className={styles.pButton}>
          <button onClick={() => setShowLevelsModal(true)}>
            <Plus />
          </button>
        </div>
      </div>{" "}
      <div className={styles.property}>
        <div>
          <div className={styles.horizontal}>
            <div className={styles.icon}>
              <Graph />
            </div>
            <div className={styles.pTitle}>Stats</div>
          </div>
          <div className={styles.pText}>
            Numerical traits that just show as numbers
          </div>
        </div>
        <div className={styles.pButton}>
          <button onClick={() => setShowStatsModal(true)}>
            <Plus />
          </button>
        </div>
      </div>
      <div className={styles.text}>Supply</div>
      <div className={styles.sm}>
        The number of items that can be minted. No gas cost to you!
      </div>
      <div>
        <input
          className={styles.input}
          placeholder="1"
          value={supply}
          disabled
          //onChange={(e) => setSupply(e.target.value)}
        />
      </div>
      <div className={styles.text}>Blockchain</div>
      <div>
        <select
          className={styles.input}
          value={chain}
          onChange={(e) => setChain(e.target.value)}
        >
          <option value="omchain">omchain</option>
        </select>
      </div>
      <div>
        <div className={styles.group}>
          <input
            type="checkbox"
            id="custom-checkbox"
            className={styles.customCheckbox}
            checked={myself}
            onChange={() => setMyself(!myself)}
          />
          <span className={styles.span} tabIndex="0"></span>
          <label htmlFor="custom-checkbox" className={styles.label}>
            I want to mint myself
          </label>
        </div>
      </div>
      <div>
        <button
          disabled={loading}
          className={styles.button}
          onClick={createNft}
        >
          {loading ? (
            <div className={styles.shimmer}>Waiting response...</div>
          ) : (
            "Create"
          )}
        </button>
        <div
          className={message.type === "error" ? styles.alert : styles.sAlert}
        >
          {message.text}
        </div>
      </div>
      <PropertiesModal
        showModal={showPropModal}
        setShowModal={setShowPropModal}
        name={name}
        type={type}
        propsHelper={propsHelper}
        setPropsHelper={setPropsHelper}
        itemCount={itemCountProps}
        setItemCount={setItemCountProps}
      />
      <LevelsModal
        showModal={showLevelsModal}
        setShowModal={setShowLevelsModal}
        levelName={levelName}
        levelMaxValue={levelMaxValue}
        levelMinValue={levelMinValue}
        levelsHelper={levelsHelper}
        setLevelsHelper={setLevelsHelper}
        itemCount={itemCountLevels}
        setItemCount={setItemCountLevels}
      />
      <StatsModal
        showModal={showStatsModal}
        setShowModal={setShowStatsModal}
        statName={statName}
        statMaxValue={statMaxValue}
        statMinValue={statMinValue}
        statsHelper={statsHelper}
        setStatsHelper={setStatsHelper}
        itemCount={itemCountStats}
        setItemCount={setItemCountStats}
      />
    </div>
  );
}
