import React from "react";
import styles from "./stake.module.css";

export default function StakeBalances({ userStakeAmount }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>Stake Balances</div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <div>Your Staked Amount</div>
          <div>{userStakeAmount ? userStakeAmount.toLocaleString("en", { minimumFractionDigits: 4 }) : 0}</div>
        </div>
      </div>
    </div>
  );
}
