import React, { useContext } from "react";
import styles from "./network.module.css";
import { Web3Context } from "../../../utils/Web3Context";
import { AppContext } from "../../../utils/Context";
import { switchNetwork } from "../../../utils/BridgeFunctions/WalletFunctions";

export default function AddNetworkButton({ depositObject }) {
  const provider = useContext(Web3Context).provider;
  const currentChainId = useContext(AppContext).currentChainId;

  async function callSwitchNetwork() {
    if (parseInt(depositObject.withdraw_network) !== currentChainId) {
      await switchNetwork(provider, parseInt(depositObject.withdraw_network));
    }
  }
  return (
    <div>
      <button className={styles.button} onClick={callSwitchNetwork}>
        Add Network
      </button>
    </div>
  );
}
