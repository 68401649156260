import React from "react";
import styles from "./header.module.css";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";

export default function Header({text, style}) {
  const isConnected = useContext(AppContext);
  return (
    <>
      {isConnected.connectionStatus && style === "home" ? (
        <>
          <div className={styles.text}>
            Now your wallet is connected, Welcome !
          </div>
          <div className={styles.smallText}>
            Which one would you like to do?
          </div>
        </>
      ) : (
        <div className={styles.text}>
          {text}
        </div>
      )}
    </>
  );
}
