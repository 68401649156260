import { Chain } from '@wagmi/core'
 
export const omchain = {
  id: 21816,
  name: 'Omchain',
  network: 'Omchain',
  nativeCurrency: {
    decimals: 18,
    name: 'Omchain',
    symbol: 'OMC',
  },
  rpcUrls: {
    public: { http: ['https://seed.omchain.io'] },
    default: { http: ['https://seed.omchain.io'] },
  },
  blockExplorers: {
    default: { name: 'Explorer', url: 'https://explorer.omchain.io' },
  }
};