import React, { useState } from "react";
import styles from "./tabs.module.css";
import { ReactComponent as Down } from "../../../assets/down.svg";

export default function ProposalTabs({ tab, setTab }) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button className={styles.container} onClick={() => setOpen(!open)}>
        <div>{tab}</div>
        <div>
          <Down />
        </div>
      </button>
      {open && (
        <div className={styles.tabs}>
          <div
            className={styles.tab}
            onClick={() => {
              setTab("All");
              setOpen(false);
            }}
          >
            All
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              setTab("Active");
              setOpen(false);
            }}
          >
            Active
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              setTab("Ended");
              setOpen(false);
            }}
          >
            Ended
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              setTab("Pending");
              setOpen(false);
            }}
          >
            Pending
          </div>
        </div>
      )}
    </div>
  );
}
