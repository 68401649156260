import React, { useEffect, useState } from "react";
import styles from "./burned.module.css";
import axios from "axios";

export default function BurnedCard() {
  const [burnedBalance, setBurnedBalance] = useState("");
  useEffect(() => {
    axios
      .get(
        "https://explorer.omchain.io/api?module=account&action=eth_get_balance&address=0x0000000000000000000000000000000000000000&block=latest"
      )
      .then((res) => setBurnedBalance(parseInt(res.data.result) / 10 ** 18));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>Burned</div>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          {burnedBalance && parseFloat(burnedBalance).toLocaleString("en", { minimumFractionDigits: 4 })}
        </div>
        <div className={styles.text}>Total Burned</div>
      </div>
    </div>
  );
}
