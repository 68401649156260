import React, { useEffect, useState } from "react";
import styles from "./supply.module.css";
import { ReactComponent as Avax } from "../../../assets/avax.svg";
import { ReactComponent as BSC } from "../../../assets/bsc.svg";
import { ReactComponent as ETH } from "../../../assets/eth.svg";
import axios from "axios";

export default function CirculatingSupply() {
  const [, setSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL_HOME + "get-max-supply")
      .then((res) => {
        setSupply(res.data.data.statistics.circulatingSupply);
        setTotalSupply(res.data.data.statistics.totalSupply);
      })
      .catch((error) => {
        console.error(error);
        setTotalSupply(249019795);
      });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.firstSection}>
        <div className={styles.sectionText}>
          <div className={styles.title}>Circulating Supply</div>
          <div className={styles.text}>162,500,000</div>
        </div>
        <div className={styles.sectionIcon}>
          <div>
            <Avax />
          </div>
          <div>
            <BSC />
          </div>
          <div>
            <ETH />
          </div>
        </div>
      </div>
      <div className={styles.thirdSection}>
        <div className={styles.supplyContainer}>
          <div className={styles.supplyTitle}>Max Supply :</div>
          <div className={styles.supplyText}>
            {totalSupply.toLocaleString("en")}
          </div>
        </div>
        <div className={styles.supplyContainer}>
          <div className={styles.supplyTitle}>Total Supply :</div>
          <div className={styles.supplyText}>
            {totalSupply.toLocaleString("en")}
          </div>
        </div>
        <div className={styles.supplyContainer}>
          <div className={styles.supplyTitle}>Wallet Addresses</div>
          <div className={styles.supplyText}>1,562,861</div>
        </div>
      </div>
    </div>
  );
}
