import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./form.module.css";
import { ReactComponent as Change } from "../../../assets/changeIcon.svg";
import { ReactComponent as Down } from "../../../assets/down.svg";
import SendButton from "../SendButton/SendButton";
import {
  callAllowanceAction,
  getTokenList,
  callApproveAciton,
  callDepositAction,
} from "../../../utils/BridgeFunctions/BridgeFunctions";
import { getChainData } from "../../../utils/BridgeFunctions/ChainData";
import { AppContext } from "../../../utils/Context";
import { Web3Context } from "../../../utils/Web3Context";
import { checkFormFields } from "../../../utils/BridgeFunctions/CheckFormFields";

export default function Form({ walletConnect }) {
  const [depositNetwork, setDepositNetwork] = useState("");
  const [withdrawNetwork, setWithdrawNetwork] = useState("");
  const [tokenList, setTokenList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [allowanceStatus, setAllowanceStatus] = useState("");
  const senderRef = useRef(null);
  const destRef = useRef(null);
  const selectRef = useRef(null);
  const [message, setMessage] = useState("");
  const amountValid = new RegExp("^[\\.0-9]*$");
  const chainData = getChainData();
  const chainId = useContext(AppContext).currentChainId;
  const web3 = useContext(Web3Context).web3;
  const account = useContext(AppContext).account;

  useEffect(() => {
    if (depositNetwork && withdrawNetwork && tokenSymbol) {
      callAllowanceAction(
        amount,
        depositNetwork,
        tokenSymbol,
        web3,
        account
      ).then((res) => setAllowanceStatus(res));
    }
  }, [depositNetwork, amount, withdrawNetwork, tokenSymbol]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeNetworks = () => {
    senderRef.current.value = withdrawNetwork;
    destRef.current.value = depositNetwork;
    setDepositNetwork(withdrawNetwork);
    setWithdrawNetwork(depositNetwork);
    if (chainId !== parseInt(withdrawNetwork)) {
      setMessage("Network on your Metamask and sender network must match!");
    }
  };

  const onAmountChange = (e) => {
    let depositValue = e.target.value;
    if (!amountValid.test(depositValue)) {
      setMessage("Invalid amount.");
    } else {
      setAmount(depositValue);
      setMessage("");
    }
  };

  const selectToken = (e) => {
    let token = e.target.value;
    if (!token) {
      setMessage("Please select token.");
    } else {
      setTokenSymbol(token);
      setMessage("");
    }
  };

  useEffect(() => {
    selectRef.current.value = "";
    setTokenSymbol("");
    if (depositNetwork === "" || depositNetwork === "-1") {
      return;
    } else if (withdrawNetwork === "" || withdrawNetwork === "-1") {
      return;
    } else {
      getTokenList(depositNetwork, withdrawNetwork).then((res) => {
        setTokenList(res);
      });
    }
  }, [depositNetwork, withdrawNetwork]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitApprove = () => {
    let errorMsg = checkFormFields(
      amount,
      depositNetwork,
      withdrawNetwork,
      tokenSymbol,
      chainId
    );
    if (errorMsg !== "") {
      setMessage(errorMsg);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setLoading(true);
      callApproveAciton(
        web3,
        depositNetwork,
        account,
        allowanceStatus,
        tokenSymbol
      ).then((res) => {
        setLoading(false);
        if (res === true) {
          setAllowanceStatus(true);
        } else {
          setMessage(res);
        }
      });
    }
  };
  const submitDeposit = () => {
    let sender = account;
    let errorMsg = checkFormFields(
      amount,
      depositNetwork,
      withdrawNetwork,
      tokenSymbol,
      chainId
    );
    if (errorMsg !== "") {
      setMessage(errorMsg);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setLoading(true);
      callDepositAction(
        web3,
        withdrawNetwork,
        depositNetwork,
        tokenSymbol,
        account,
        sender,
        amount
      ).then((res) => {
        setMessage(res);
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.firstSection}>
          <div className={styles.select}>
            <div className={styles.selectTitle}>Sender Network</div>
            <div className={styles.box}>
              <select
                onChange={(e) => setDepositNetwork(e.target.value)}
                ref={senderRef}
              >
                <option value="-1">Select a sender network</option>
                {Object.keys(chainData.supportedNetworks).map(function (key) {
                  return (
                    <option
                      key={key}
                      value={chainData.supportedNetworks[key].chainId}
                    >
                      {chainData.supportedNetworks[key].name}{" "}
                    </option>
                  );
                })}
              </select>
              <Down className={styles.icon} />
            </div>
          </div>
          <div>
            <button className={styles.button} onClick={changeNetworks}>
              <Change />
            </button>
          </div>
          <div className={styles.select}>
            <div className={styles.selectTitle}>Destination Network</div>
            <div className={styles.box}>
              <select
                onChange={(e) => setWithdrawNetwork(e.target.value)}
                ref={destRef}
              >
                <option value="-1">Select a destination network</option>
                {Object.keys(chainData.supportedNetworks).map(function (key) {
                  return (
                    <option
                      key={key}
                      value={chainData.supportedNetworks[key].chainId}
                    >
                      {chainData.supportedNetworks[key].name}{" "}
                    </option>
                  );
                })}
              </select>
              <Down className={styles.icon} />
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.selectToken}>
            <div className={styles.selectTitle}>Token</div>
            <div className={styles.box}>
              <select onChange={selectToken} ref={selectRef}>
                <option value="">Select token</option>
                {tokenList.map((token, i) => {
                  return <option key={i}>{token}</option>;
                })}
              </select>
              <Down className={styles.icon} />
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.input}>
            <div className={styles.selectTitle}>Amount</div>
            <div>
              <input onChange={onAmountChange}></input>
            </div>
          </div>
        </div>
        <div className={styles.alert}>{message}</div>
      </div>
      <SendButton
        allowanceStatus={allowanceStatus}
        submitApprove={submitApprove}
        submitDeposit={submitDeposit}
        walletConnect={walletConnect}
        loading={loading}
      />
    </div>
  );
}
