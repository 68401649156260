import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoWh } from "../../assets/logoWh.svg";
import { ReactComponent as Hamburger } from "../../assets/hamburger.svg";
import { ReactComponent as Exit } from "../../assets/exit.svg";
import { ReactComponent as Dark } from "../../assets/dark.svg";
import { ReactComponent as Light } from "../../assets/light.svg";
import { useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import ConnectButton from "../HomeComponents/ConnectButton/ConnectButton";
import SocialBar from "../HomeComponents/SocialBar/SocialBar";

export default function Navbar({
  walletConnect,
  walletDisconnect,
  toggleDarkMode,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  async function callWalletConnect() {
    walletConnect();
  }
  async function callWalletDisconnect() {
    walletDisconnect();
  }

  return (
    <div className={styles.container} theme={localStorage.getItem("theme")}>
      <div className={styles.horizontal}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          {localStorage.getItem("theme") === "dark" ? <LogoWh /> : <Logo />}
        </div>
        <div className={styles.links}>
          <div
            onClick={() => [navigate("/"), setActiveLink("home")]}
            tabIndex={1}
            className={
              activeLink === "home" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            home
          </div>
          <div
            onClick={() => [navigate("/bridge"), setActiveLink("bridge")]}
            tabIndex={1}
            className={
              activeLink === "bridge"
                ? `${styles.linkActive}`
                : `${styles.link}`
            }
          >
            bridge
          </div>
          <div
            onClick={() => [navigate("/stake"), setActiveLink("stake")]}
            tabIndex={1}
            className={
              activeLink === "stake" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            stake
          </div>
          <div
            onClick={() => [navigate("/wall"), setActiveLink("wall")]}
            tabIndex={1}
            className={
              activeLink === "wall" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            wall
          </div>
          <div
            onClick={() => [navigate("/burn"), setActiveLink("burn")]}
            tabIndex={1}
            className={
              activeLink === "burn" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            burn
          </div>
          <div
            onClick={() => [navigate("/vote"), setActiveLink("vote")]}
            tabIndex={1}
            className={
              activeLink === "vote" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            vote
          </div>
          <div
            onClick={() => [navigate("/create-nft"), setActiveLink("mint")]}
            tabIndex={1}
            className={
              activeLink === "mint" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            mint
          </div>
          <div
            onClick={() => [navigate("/browse-nft"), setActiveLink("browse")]}
            tabIndex={1}
            className={
              activeLink === "browse" ? `${styles.linkActive}` : `${styles.link}`
            }
          >
            browse
          </div>
        </div>
        {showMenu ? (
          <div className={styles.mobileLinks}>
            <div>
              <ConnectButton
                isMobile={true}
                callWalletConnect={callWalletConnect}
                callWalletDisconnect={callWalletDisconnect}
              />
            </div>
            <div className={styles.mobileList}>
              <div
                onClick={() => [navigate("/"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                home
              </div>
              <div
                onClick={() => [navigate("/bridge"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                bridge
              </div>
              <div
                onClick={() => [navigate("/stake"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                stake
              </div>
              <div
                onClick={() => [navigate("/wall"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                wall
              </div>
              <div
                onClick={() => [navigate("/burn"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                burn
              </div>
              <div
                onClick={() => [navigate("/vote"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                vote
              </div>
              <div
                onClick={() => [navigate("/create-nft"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                mint
              </div>
              <div
                onClick={() => [navigate("/browse-nft"), setShowMenu(false)]}
                tabIndex={1}
                className={styles.linkMobile}
              >
                browse
              </div>
            </div>
            <div>
              <SocialBar isMobile={true} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.button}>
          <ConnectButton
            callWalletConnect={callWalletConnect}
            callWalletDisconnect={callWalletDisconnect}
            type={"navbar"}
          />
          <div>
            <button className={styles.toggle} onClick={toggleDarkMode}>
              {localStorage.getItem("theme") === "dark" ? (
                <Light className={styles.light} />
              ) : (
                <Dark />
              )}
            </button>
          </div>
          <div className={styles.hamburger}>
            <button onClick={() => setShowMenu(!showMenu)}>
              {showMenu ? <Exit /> : <Hamburger />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
