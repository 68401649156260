import React from "react";
import styles from "./amountCard.module.css";
import { ReactComponent as OmcLogo } from "../../../assets/omcIcon.svg";
import TetherLogo from "../../../assets/tetherLogo.png";
import AddNetworkButton from "../AddNetworkButton/AddNetworkButton";
import AddTokenButton from "../AddTokenButton/AddTokenButton";
import { BigNumber } from "bignumber.js";

export default function AmountCard({ depositObject, setMessage }) {
  let amount = "";
  if (depositObject.deposit_amount) {
    const depositAmountWei = new BigNumber(depositObject.deposit_amount);
    const depositAmountEther = depositAmountWei.dividedBy(new BigNumber(10).pow(18)); // Convert from wei to ether
    amount = depositAmountEther.toFormat(4); // Display at most 4 decimal places
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Transferred Amount</div>
      <div className={styles.amount}>{amount}</div>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          {depositObject.token_symbol === "USDT" ? (
            <img src={TetherLogo} style={{ width:'32px', height:'32px'}} alt="Tether Logo" />)
             : <OmcLogo />}
        </div>
        <div className={styles.omc}>{depositObject.token_symbol}</div>
      </div>
      <div className={styles.buttonContainer}>
        <div>
          <AddNetworkButton
            depositObject={depositObject}
            setMessage={setMessage}
          />
        </div>
        <div>
          <AddTokenButton
            depositObject={depositObject}
            setMessage={setMessage}
          />
        </div>
      </div>
    </div>
  );
}
