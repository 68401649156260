import React, { useContext } from "react";
import styles from "./token.module.css";
import { Web3Context } from "../../../utils/Web3Context";
import { AppContext } from "../../../utils/Context";
import { addToken } from "../../../utils/BridgeFunctions/WalletFunctions";

export default function AddTokenButton({ depositObject, setMessage }) {
  const provider = useContext(Web3Context).provider;
  const currentChainId = useContext(AppContext).currentChainId;

  async function callAddToken() {
    let errorMsg = await addToken(
      currentChainId,
      provider,
      parseInt(depositObject.withdraw_network),
      depositObject.token_symbol
    );
    if (errorMsg !== "") {
      setMessage(errorMsg);
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  }
  return (
    <div>
      <button className={styles.button} onClick={callAddToken}>
        Add Token
      </button>
    </div>
  );
}
