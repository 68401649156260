import React, { useEffect, useState } from "react";
import styles from "./switch.module.css";
import { useContext } from "react";
import { Web3Context } from "../../../utils/Web3Context";
import { AppContext } from "../../../utils/Context";

export default function SwitchButton() {
  const provider = useContext(Web3Context).provider;
  const walletConnect = useContext(Web3Context).walletConnect;
  const chainId = useContext(AppContext).currentChainId;
  const [chainStatus, setChainStatus] = useState(false);

  useEffect(() => {
    if (chainId) {
      if (
        parseInt(chainId) === 21816 ||
        parseInt(chainId) === 9102020 ||
        chainId === "0x8ae2c4"
      ) {
        setChainStatus(true);
      } else {
        setChainStatus(false);
      }
    }
  }, [chainId]);

  async function switchNetwork() {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID_AS_HEX }],
      });
      setChainStatus(true);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: process.env.REACT_APP_CHAIN_ID_AS_HEX,
                chainName: process.env.REACT_APP_NETWORK_NAME,
                nativeCurrency: {
                  name: process.env.REACT_APP_TOKEN_NAME,
                  symbol: process.env.REACT_APP_SYMBOL,
                  decimals: 18,
                },
                rpcUrls: [process.env.REACT_APP_RPC_URL],
              },
            ],
          });
          walletConnect();
        } catch (addError) {}
      }
    }
  }

  return (
    <button
      onClick={switchNetwork}
      className={styles.container}
      disabled={chainStatus}
    >
      {chainStatus ? (
        <div>Welcome to omchain</div>
      ) : (
        <div>Switch to omchain</div>
      )}
    </button>
  );
}
