export function convertAtomicToReadable(result) {
  return result / 10 ** 18;
}

export function countDownTimerUtil(stakeCreationTime, _valorDuration) {
  let stakeCreationDateObject = new Date(stakeCreationTime * 1000);
  let valorDuration = _valorDuration * 1000;
  let currentDateObject = new Date();
  let diff = currentDateObject.getTime() - stakeCreationDateObject.getTime();
  return valorDuration - diff;
}

export function dateConversionForStake(_stakeCreationTime, _minRewardDuration) {
  let stakeCreationTime = new Date(_stakeCreationTime * 1000).getTime();
  let minRewardDuration = _minRewardDuration * 1000;
  return new Date(stakeCreationTime + minRewardDuration).toLocaleDateString(
    "tr-TR"
  );
}
