import React, { useState, useRef, useEffect, memo } from "react";
import { formatTime } from "../../utils/BridgeFunctions/FormatTime";

const TransferTimer = ({ status, confirmedTime, generatedTime }) => {
  const [timer, setTimer] = useState(0);
  const countRef1 = useRef(null);

  const calculateGeneratedMinusConfirmedTime = () => {
    let depositConfirmedTime = new Date(confirmedTime).getTime();
    let ticketGeneratedTime = new Date(confirmedTime).getTime();
    let diffTime = ticketGeneratedTime - depositConfirmedTime;
    return Math.trunc(diffTime / 1000);
  };

  const handleStart = () => {
    countRef1.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(countRef1.current);
  };

  useEffect(() => {
    if (status === 3) {
      handlePause();
      setTimer(calculateGeneratedMinusConfirmedTime());
      handleStart();
    } else {
      handlePause();
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="app">
      <div className="stopwatch-card">
        {status ? <p>{formatTime(timer)}</p> : <p>00:00</p>}
      </div>
    </div>
  );
};

export default memo(TransferTimer);
