import React, { useState } from "react";
import styles from "./withdrawal.module.css";
import { ReactComponent as Logo } from "../../../assets/omcIcon.svg";
import Countdown from "react-countdown";
import { countDownTimerUtil } from "../../../utils/StakeFunctions/Conversion";
import { Web3Context } from "../../../utils/Web3Context";
import { callClaim } from "../../../utils/StakeFunctions/StakeV2Functions";

export default function WithDrawalCard({
  withdrawal,
  i,
  renderHelper,
  setRenderHelper,
}) {
  const [loading, setLoading] = useState(false);
  let currentTime = Math.round(new Date().getTime() / 1000);
  let timeLeft = withdrawal[1] - currentTime;
  let date = new Date(withdrawal[1] * 1000).toLocaleDateString();
  
  async function handleExitAction() {
    setLoading(true);
    try {
      let status = await callClaim(i);
      setLoading(false);
      setRenderHelper(!renderHelper);
      if (status === true) {
        Web3Context.getBalance();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.horizontal}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.title}>OMC</div>
        </div>
        <div className={styles.horizontal}>
          <div className={styles.index}>#{i}</div>
          {timeLeft >= 0 ? (
            <Countdown
              className={styles.timer}
              date={
                Date.now() + (timeLeft > 0 ? timeLeft * 1000 : 0)
              }
              ></Countdown>
          ) : (
            <>
            {loading ? (
              <div className={styles.shimmer}>Waiting response...</div>
            ) : (
              <button
                className={styles.button}
                onClick={handleExitAction}
              >
                Withdraw
              </button>
            )}
            </>
          )}
          <div></div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.text}>Amount</div>
          <div className={styles.value}>
            {parseFloat(withdrawal[0] / 10 ** 18).toLocaleString("en", { minimumFractionDigits: 4 })} OMC
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.text}>Date</div>
          <div className={styles.value}>{date}</div>
        </div>
        <div className={styles.line}></div>
      </div>
    </div>
  );
}
