import React, { useEffect, useContext, useState } from "react";
import styles from "./detail.module.css";
import NftImage from "../../components/NftDetail/NftImage/NftImage";
import NftProps from "../../components/NftDetail/NftProps.js/NftProps";
import NftWallet from "../../components/NftDetail/NftWallet/NftWallet";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../utils/Context";
import TranferModal from "../../components/NftDetail/TransferModal/TransferModal";
import { Helmet } from "react-helmet";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export default function NftDetail() {
  let { contractAddress } = useParams();
  let { tokenId } = useParams();
  const isConnected = useContext(AppContext);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const getNftDetail = () => {
    setLoading(true);
    api
      .post(`nft-minter/detail/${contractAddress}/${tokenId}`, {
        walletAddress: isConnected.connectedAccount,
      })
      .then((res) => {
        setDetail(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    getNftDetail();
  // eslint-disable-next-line
  }, [isConnected]);

  return (
    <>
      <Helmet>
        <meta
          name="twitter:title"
          content="Click to see my minted nft on omchain !"
        />
        <meta
          name="twitter:description"
          content="Minted nft on omchain, check out app.omchain.io !"
        />
        <meta
          name="description"
          content="Easiest way to interact with omchain applications. omchain Web App provides the necessary tools for bridging OMC between different networks, staking interface, a wall where you can share your ideas and mint them as NFTs and browse supply and market statistics for OMC. "
        />
        <meta
          property="og:title"
          content="Click to see my minted nft on omchain !"
        />
      </Helmet>
      {loading ? (
        <div className={styles.empty}>
          <div className={styles.shimmer}>Loading...</div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.horizontal}>
              <NftImage detail={detail} />
              <div className={styles.contentS}>
                <div className={styles.title}>{detail && detail.name}</div>
                <NftProps detail={detail} />
                <div className={styles.contentB}>
                  <button
                    className={styles.accept}
                    onClick={() => setShow(true)}
                  >
                    Transfer
                  </button>
                  <button
                    className={styles.accept}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://app.omchain.io/nft-detail/${contractAddress}/${tokenId}`
                      );
                      setWarningMessage("Share link has copied");
                      setTimeout(() => setWarningMessage(""), 3000);
                    }}
                  >
                    Share
                  </button>
                </div>
                <p>{warningMessage}</p>
              </div>
            </div>
            <NftWallet detail={detail} />
          </div>
        </div>
      )}
      <TranferModal
        show={show}
        setShow={setShow}
        tokenId={detail && detail.token_id}
        walletAddress={isConnected.connectedAccount}
      />
    </>
  );
}
