import React, { useEffect, useContext, useState } from "react";
import styles from "./omcBalances.module.css";
import axios from "axios";
import { AppContext } from "../../../utils/Context";

export default function OmcBalancesCard() {
  const account = useContext(AppContext).account;
  const [bscAmount, setBscAmount] = useState();
  const [ethAmount, setEthAmount] = useState();
  const [avaxAmount, setAvaxAmount] = useState();
  const [omcAmount, setOmcAmount] = useState();

  useEffect(() => {
    const getBscAmount = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-omc-in-bsc/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setBscAmount(parseInt(res.data.result));
            clearInterval(getBscAmount);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getEthAmount = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-omc-in-bsc/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setEthAmount(parseInt(res.data.result));
            clearInterval(getEthAmount);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getAvaxAmount = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-omc-in-avax/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setAvaxAmount(parseInt(res.data.result));
            clearInterval(getAvaxAmount);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getOmcAmount = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-omc-in-omc/" + account
        )
        .then((res) => {
          setOmcAmount(res.data.result);
          clearInterval(getOmcAmount);
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={styles.container}>
      <div className={styles.header}>OMC Balances</div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <div>OMC's in ETH</div>
          <div>
            {" "}
            {ethAmount !== undefined
              ? parseFloat(ethAmount / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 4 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>OMC's in BSC</div>
          <div>
            {bscAmount !== undefined
              ? parseFloat(bscAmount / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 4 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>OMC's in AVAX</div>
          <div>
            {" "}
            {avaxAmount !== undefined
              ? parseFloat(avaxAmount / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 4 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>OMC's in OMC</div>
          <div>
            {" "}
            {omcAmount !== undefined
              ? parseFloat(omcAmount / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 4 }
                )
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
}
