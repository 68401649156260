import React from "react";
import styles from "./balance.module.css";
import { ReactComponent as Omc } from "../../../assets/omcIcon.svg";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";

export default function BalanceContainer() {
  let balance = useContext(AppContext).accountBalance;
  let connectionStatus = useContext(AppContext).connectionStatus;
  let chainId = useContext(AppContext).currentChainId;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.logo}>
          <Omc />
        </div>
        <div className={styles.text}>Balance</div>
      </div>
      <div className={styles.amount}>
        <div>
          {!connectionStatus ||
          parseInt(chainId) !==
            parseInt(process.env.REACT_APP_STAKE_CORRECT_CHAIN_ID)
            ? "--"
            : parseFloat(balance).toLocaleString("en", { minimumFractionDigits: 4 })}
        </div>
        <div className={styles.omc}>OMC</div>
      </div>
    </div>
  );
}
