import React, { useState, useContext, useEffect } from "react";
import styles from "./proposals.module.css";
import ProposalCard from "../../components/VoteComponents/ProposalCard/ProposalCard";
import { AppContext } from "../../utils/Context";
import WrongNetworkModal from "../../components/StakeComponents/WrongNetworkModal/WrongNetworkModal";
import Header from "../../components/HomeComponents/Header/Header";
import ProposalTabs from "../../components/VoteComponents/ProposalTabs/ProposalTabs";
import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_LOCAL_URL });

export default function Proposals() {
  const connectionStatus = useContext(AppContext).connectionStatus;
  const isCorrectNetwork = useContext(AppContext).isCorrectNetworkForStake;
  const [showWrongModal, setShowWrongModal] = useState(false);
  const [tab, setTab] = useState("All");
  const [proposals, setProposals] = useState([]);
  const [active, setActive] = useState([]);
  const [ended, setEnded] = useState([]);
  const [scheduled, setScheduled] = useState([]);

  useEffect(() => {
    if (connectionStatus) {
      if (isCorrectNetwork) {
        setShowWrongModal(false);
      } else {
        setShowWrongModal(true);
      }
    }
  }, [connectionStatus, isCorrectNetwork]);

  useEffect(() => {
    setProposals([...active, ...ended, ...scheduled]);
  }, [active, ended, scheduled]);

  useEffect(() => {
    if (tab === "Active") {
      api
        .get("ovote/active-proposals")
        .then((res) => setProposals(res.data.data))
        .catch((e) => console.error(e));
    } else if (tab === "Ended") {
      api
        .get("ovote/ended-proposals")
        .then((res) => setProposals(res.data.data))
        .catch((e) => console.error(e));
    } else if (tab === "Pending") {
      api
        .get("ovote/scheduled-proposals")
        .then((res) => setProposals(res.data.data))
        .catch((e) => console.error(e));
    } else {
      api
        .get("ovote/active-proposals")
        .then((res) => setActive(res.data.data))
        .catch((e) => console.error(e));
      api
        .get("ovote/ended-proposals")
        .then((res) => setEnded(res.data.data))
        .catch((e) => console.error(e));
      api
        .get("ovote/scheduled-proposals")
        .then((res) => setScheduled(res.data.data))
        .catch((e) => console.error(e));
    }
  }, [tab]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header text={"Proposals"} />
        <ProposalTabs tab={tab} setTab={setTab} />
      </div>
      <div className={styles.list}>
        {proposals.length > 0 ? (
          proposals.map((proposal, i) => {
            return (
              <ProposalCard
                key={i}
                name={proposal.name}
                id={proposal.generated_id}
                date={proposal.remaining_time}
                tab={tab}
                image={proposal.image}
                description={proposal.description}
                status={proposal.status}
              />
            );
          })
        ) : (
          <div className={styles.empty}>There are no proposals here</div>
        )}
      </div>
      <WrongNetworkModal
        showWrongModal={showWrongModal}
        setShowWrongModal={setShowWrongModal}
      />
    </div>
  );
}
