import React from "react";
import styles from "./button.module.css";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";
import { ReactComponent as Wallet } from "../../../assets/wallet.svg";

export default function ConnectButton({
  type,
  callWalletConnect,
  callWalletDisconnect,
  isMobile,
}) {
  const isConnected = useContext(AppContext);
  return (
    <div>
      <button
        onClick={
          isConnected.connectionStatus
            ? callWalletDisconnect
            : callWalletConnect
        }
        className={
          type === "home"
            ? `${styles.buttonHome}`
            : isMobile
            ? `${styles.buttonMobile}`
            : `${styles.button}`
        }
      >
        {isConnected.connectionStatus ? (
          type === "home" ? (
            isConnected.connectedAccount
          ) : (
            <>
              <div className={!isMobile && styles.walletAddress}>
                {isConnected.connectedAccount.substring(0, 6) +
                  "..." +
                  isConnected.connectedAccount.substring(38, 44)}
              </div>
              {!isMobile && (
                <div className={styles.walletIcon}>
                  {isConnected.connectionStatus &&
                  isConnected.connectedAccount ? (
                    isConnected.connectedAccount.substring(0, 5)
                  ) : (
                    <Wallet />
                  )}
                </div>
              )}
            </>
          )
        ) : (
          <>
            {" "}
            <div
              className={
                isMobile ? `${styles.addressMobile}` : `${styles.address}`
              }
            >
              Connect Wallet
            </div>
            {isMobile ? (
              <></>
            ) : (
              <div className={styles.icon}>
                <Wallet />
              </div>
            )}
          </>
        )}
      </button>
    </div>
  );
}
