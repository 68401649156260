import { getAbi } from "./Abi";

const abi = getAbi();

export async function jupiterRecord(
  web3,
  account,
  encryptedMessage,
  setLoading,
  setIsMessage,
  isMessage
) {
  setLoading(true);
  let sentTx = false;
  try {
    let contract = await new web3.eth.Contract(
      abi,
      "0x4B90E2456d8873cB0779fD860E8db501b87F19BE"
    );
    sentTx = await contract.methods.write("0x" + encryptedMessage).send({
      from: account,
      gasPrice: web3.utils.toWei("300", "gwei"),
    });
    setLoading(false);
    if (setIsMessage) {
      setIsMessage(!isMessage);
    }
  } catch (e) {
    console.error(e);
    sentTx = false;
    setLoading(false);
  }

  return sentTx;
}
