import React, { useState, useContext } from "react";
import axios from "axios";
import styles from "./write.module.css";
import { jupiterRecord } from "../../utils/WallFunctions/WallFunctions";
import {
  getBlackList,
  checkMessageValid,
} from "../../utils/WallFunctions/BlackList";
import { Web3Context } from "../../utils/Web3Context";
import { AppContext } from "../../utils/Context";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_WALL,
});

export default function WriteContainer({
  isMessage,
  setIsMessage,
  isPage,
  txHash,
}) {
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const messageBlackList = getBlackList();
  const web3 = useContext(Web3Context).web3;
  const walletConnect = useContext(Web3Context).walletConnect;
  const account = useContext(AppContext).account;
  const connectionStatus = useContext(AppContext).connectionStatus;

  const submitMessage = () => {
    setLoading(true);
    let isValid = checkMessageValid(username, message, messageBlackList);
    if (!isValid) {
      setWarningMessage(
        "Please check your username and message, do not use the forbidden words."
      );
      setInterval(() => {
        setWarningMessage("");
      }, 3500);
      setLoading(false);
    } else {
      if (isPage === "detail") {
        api
          .post("create-child-message", {
            owner: username,
            message: message,
            parent_tx_hash: txHash,
          })
          .then((response) => {
            jupiterRecord(
              web3,
              account,
              response.data.data.message_hash,
              setLoading,
              setIsMessage,
              isMessage
            ).then((res) => {
              if (res.status && res.status === true) {
                setTimeout(() => {
                  window.location.reload();
                }, 2500);
              }
            });
          })
          .catch(() => {
            setWarningMessage("Sorry, error while saving to omchain");
            setLoading(false);
          });
      } else {
        api
          .post("create-message", {
            owner: username,
            message: message,
            signedFlag: false,
          })
          .then((response) => {
            jupiterRecord(
              web3,
              account,
              response.data.data.message_hash,
              setLoading,
              setIsMessage,
              isMessage
            );
          })
          .catch(() => {
            setWarningMessage("Sorry, error while saving to omchain");
            setLoading(false);
          });
      }
    }
  };
  return (
    <div className={styles.container}>
      {isPage !== "detail" && (
        <div className={styles.title}>The NFT Wall</div>
      )}
      <div>
        <textarea
          placeholder="What are you thinking today?"
          className={styles.message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div>
        <input
          placeholder="Username"
          className={styles.username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className={styles.alert}>{warningMessage}</div>
      <div>
        {connectionStatus ? (
          <button className={styles.button} onClick={submitMessage}>
            {loading ? (
              <div className={styles.shimmer}>Waiting response...</div>
            ) : (
              "Write"
            )}
          </button>
        ) : (
          <button className={styles.button} onClick={walletConnect}>
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
}
