import React, { useEffect, useState } from "react";
import styles from "./omlink.module.css";
import axios from "axios";

export default function OmlinkCard() {
  const [linked, setLinked] = useState();
  useEffect(() => {
    const getOmlinked = setInterval(() => {
      axios
        .get(process.env.REACT_APP_SERVER_URL_HOME + "get-total-bridged")
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setLinked(res.data.result);
            clearInterval(getOmlinked);
          }
        });
    }, 1500);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>Bridge</div>
      {linked !== undefined ? (
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {linked !== undefined
              ? parseInt(linked / 1000000000000000000).toLocaleString("en")
              : "--"}
          </div>
          <div className={styles.text}>Total Bridge</div>
        </div>
      ) : (
        "--"
      )}
    </div>
  );
}
