import React, { useState } from "react";
import styles from "./enterV2.module.css";
import AddStakeModal from "../AddStakeModal/AddStakeModal";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";

export default function EnterAmountContainerV2({
  amount,
  setAmount,
  renderHelper,
  setRenderHelper,
  getUserStakes
}) {
  const amountValid = new RegExp("^[\\.0-9]*$");
  let balance = useContext(AppContext).accountBalance;
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const minAmount = useState(100);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const options = [
    { value: 1, label: "30 Days" },
    { value: 2, label: "60 Days" },
    { value: 3, label: "180 Days" },
    { value: 4, label: "360 Days" },
    { value: 5, label: "720 Days" }
  ];

  const onAmountChange = (e) => {
    let enteredAmount = e.target.value;
    if (!amountValid.test(enteredAmount)) {
      setMessage("Invalid amount");
    } else {
      setMessage("");
      if (parseFloat(enteredAmount) > parseFloat(balance)) {
        setMessage("The amount entered is not in your wallet.");
      } else if (parseFloat(enteredAmount) < parseFloat(minAmount)) {
        setMessage(
          "Amount entered cannot be less than the minimum stake amount."
        );
      } else {
        setMessage("");
        setAmount(enteredAmount);
      }
    }
  };

  const submitStake = () => {
    if (!amount) {
      setMessage("Please enter a valid amount");
    } else if (selectedOption === null) {
      setMessage("Please select a duration");
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <input
          className={styles.input}
          onChange={onAmountChange}
          placeholder="Enter amount"
        />
      </div>
      <div>
        <select className={styles.select} defaultValue={-1} onChange={handleSelectChange}>
          <option value="-1" disabled hidden>Choose Duration</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <div>
        <button className={styles.button} onClick={submitStake}>
          Stake
        </button>
      </div>
      <div className={styles.alert}>{message}</div>
      {amount && (
        <AddStakeModal
          showModal={showModal}
          setShowModal={setShowModal}
          amount={amount}
          stakeTerm={selectedOption}
          renderHelper={renderHelper}
          setRenderHelper={setRenderHelper}
        />
      )}
    </div>
  );
}
