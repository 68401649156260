import { convertAtomicToReadable } from "./Conversion";
import { getWeb3Object } from "../../App";
import getAbi from "./Abi";
import GetWeb3 from "../GetWeb3";

const abi = getAbi();

export async function addStakeAction(amount, stakeTerm) {
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  let status = await contract.methods.stake(web3.utils.toWei(amount.toString(),"ether"), stakeTerm).send({
    from: currentAccount,
    value: web3.utils.toWei(amount.toString(), "ether"),
  });

  return status["status"];
}

export async function callExitAction(stakeIndex) {
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  let status = await contract.methods.exit(stakeIndex).send({
    from: currentAccount,
  });

  return status["status"];
}

export async function callClaim(withdrawalIndex) {
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  let status = await contract.methods.claim(withdrawalIndex).send({
    from: currentAccount,
  });

  return status["status"];
}

export async function getStakes() {
  let result = [];
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  result = await contract.methods.stakes(currentAccount).call();
  return result;
}

export async function getWithdrawals() {
  let result;
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  result = await contract.methods.withdrawals(currentAccount).call();
  return result;
}

export async function getEarned(stakeIndex) {
  let result;
  let web3 = await getWeb3Object();
  let accounts = await web3.eth.getAccounts();
  let currentAccount = accounts[0].toLowerCase();
  let contract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
  );
  result = await contract.methods.earned(currentAccount, stakeIndex).call();
  return result;
}