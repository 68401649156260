import React, { useRef } from "react";
import styles from "./modal.module.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Back } from "../../../assets/arrowLeft.svg";

export default function PropsModal({
  showModal,
  setShowModal,
  type,
  name,
  propsHelper,
  setPropsHelper,
  itemCount,
  setItemCount,
}) {
  const typeRef = useRef(null);
  const nameRef = useRef(null);

  var items = [];

  for (let i = 0; i < itemCount; i++) {
    items.push(
      <div className={styles.horizontal} key={i}>
        <div className={styles.input}>
          <div className={styles.delete}>
            <button
              onClick={() => {
                if (itemCount === 1) {
                  setItemCount(1);
                  type.splice(type[i], 1);
                  name.splice(name[i], 1);
                } else {
                  setItemCount(itemCount - 1);
                  type.splice(type[i], 1);
                  name.splice(name[i], 1);
                }
              }}
            >
              X
            </button>
          </div>
          <input
            placeholder="Character"
            ref={typeRef}
            value={type[i]}
            onChange={() => (type[i] = typeRef.current.value)}
          ></input>
        </div>
        <div className={styles.input}>
          <input
            placeholder="Male"
            ref={nameRef}
            value={name[i]}
            onChange={() => (name[i] = nameRef.current.value)}
          ></input>
        </div>
      </div>
    );
  }

  const saveAction = () => {
    setShowModal(false);
    setPropsHelper(!propsHelper);
  };

  return (
    <Modal
      show={showModal}
      onHide={!showModal}
      animation={true}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div
          onClick={() => {
            setShowModal(false);
          }}
          className={styles.back}
        >
          <Back />
        </div>
        <div className={styles.title}>Add Properties</div>
        <div className={styles.message}>
          Properties show up underneath your item, are clickable, and can be
          filtered in your collection's sidebar.
        </div>
        <div className={styles.horizontalT}>
          <div className={styles.type}>Type</div>
          <div className={styles.type}>Name</div>
        </div>
        {items.map((item) => {
          return item;
        })}
        <div className={styles.buttonContainer}>
          <div>
            <button
              onClick={() => {
                setItemCount(itemCount + 1);
              }}
              className={styles.accept}
            >
              Add More
            </button>{" "}
          </div>
        </div>
        <div>
          <button onClick={saveAction} className={styles.save}>
            Save
          </button>{" "}
        </div>
      </div>
    </Modal>
  );
}
