import "./App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import { AppContext } from "./utils/Context";
import { Web3Context } from "./utils/Web3Context";
import Web3 from "web3";
// import { getWeb3Modal } from "./utils/Web3Constants";
import { getStakes } from "./utils/StakeFunctions/StakeV2Functions";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Bridge from "./pages/Bridge/Bridge";
import Status from "./pages/Bridge/Status";
import StakeV2 from "./pages/Stake/StakeV2";
import StakeDetail from "./pages/Stake/StakeDetail";
import SocialBar from "./components/HomeComponents/SocialBar/SocialBar";
import Wall from "./pages/Wall/Wall.js";
import Post from "./pages/Wall/Post";
import Burn from "./pages/Burn/Burn";
import Proposals from "./pages/Vote/Proposals";
import Detail from "./pages/Vote/Detail";
import CreateNft from "./pages/CreateNft/CreateNft";
import BrowseNft from "./pages/BrowseNFT/BrowseNft";
import NftDetail from "./pages/NftDetail/NftDetail";

import { useWeb3Modal } from "@web3modal/react";
import { useEthersSigner, useWeb3Signer } from "./utils/EthersHooks";
import { useDisconnect } from "wagmi";

let web3;
let provider;
let account;
let balance;

export async function getWeb3Object() {
  try {

    return web3;
  } catch (e) {
    console.error(e);
  }
}

function App() {
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState("");
  const [currentChainId, setCurrentChainId] = useState(false);
  const [accountBalance, setAccountBalance] = useState("");
  const [userStakes, setUserStakes] = useState("");
  const [userStakeAmount, setUserStakeAmount] = useState("");
  const [, setTheme] = useState("");
  const [isCorrectNetworkForStake, setIsCorrectNetworkForStake] =
    useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);

  let themeStorage = localStorage.getItem("theme");
  localStorage.setItem("theme", themeStorage);
  document.documentElement.setAttribute("theme", themeStorage);




  web3 = useWeb3Signer();
  const ethersProvider = useEthersSigner();

  const { open, clearCachedProvider  } = useWeb3Modal();

  const {
    disconnect
  } = useDisconnect();





  useEffect(() => {
    const funky = async () => {
      if (ethersProvider) {
        try {
          provider = ethersProvider.provider;
          provider.on("chainChanged", (chainId) => {
            setCurrentChainId(chainId);
            setIsCurrentChainValues(chainId);
            setIsCurrentChain(chainId);
            getBalance();
            callGetStakes();
          });
          provider.on("accountsChanged", (error) => {
            localStorage.setItem("isWeb3AlreadyConnected", false);
            setConnectionStatus(false);
            getStakeAmount();
            clearCachedProvider();
          });

          let accounts = await web3.eth.getAccounts();
          let chainId = await web3.eth.getChainId();
          account = accounts[0].toLowerCase();
          setConnectedAccount(account);
          setConnectionStatus(true);
          setCurrentChainId(chainId);
          getBalance();
          callGetStakes();
          setIsCurrentChainValues(chainId);
          setIsCurrentChain(chainId);
          localStorage.setItem("isWeb3AlreadyConnected", true);
        } catch (e) {
          console.error(e);
        }
      }
    };
    funky();
  }, [ethersProvider]);

  async function walletConnect() {
    try {
      // const web3Modal = getWeb3Modal();
      open();
      // provider = ethersProvider.provider;

      // console.log("provider", provider);
      /** 
      provider.on("chainChanged", (chainId) => {
        console.log("Wut");
      });

      provider.on("chainChanged", (chainId) => {
        setCurrentChainId(chainId);
        setIsCurrentChainValues(chainId);
        setIsCurrentChain(chainId);
        getBalance();
        callGetStakes();
      });
      provider.on("accountsChanged", (error) => {
        localStorage.setItem("isWeb3AlreadyConnected", false);
        setConnectionStatus(false);
        getStakeAmount();
        clearCachedProvider();
      });

      let accounts = await web3.eth.getAccounts();
      let chainId = await web3.eth.getChainId();
      account = accounts[0].toLowerCase();
      setConnectedAccount(account);
      setConnectionStatus(true);
      setCurrentChainId(chainId);
      getBalance();
      callGetStakes();
      setIsCurrentChainValues(chainId);
      setIsCurrentChain(chainId);
      localStorage.setItem("isWeb3AlreadyConnected", true);
        */
    } catch (e) {
      console.error(e);
    }
  }

  function setIsCurrentChainValues(chainId) {
    parseInt(chainId) === parseInt(process.env.REACT_APP_STAKE_CORRECT_CHAIN_ID)
      ? setIsCorrectNetworkForStake(true)
      : setIsCorrectNetworkForStake(false);
  }

  function setIsCurrentChain(chainId) {
    parseInt(chainId) === parseInt(process.env.REACT_APP_CORRECT_CHAIN_ID)
      ? setIsCorrectNetwork(true)
      : setIsCorrectNetwork(false);
  }

  async function walletDisconnect() {
    try {
      disconnect();
   clearCachedProvider();
      setCurrentChainId("");
      setConnectedAccount("");
      setConnectionStatus(false);
      localStorage.setItem("isWeb3AlreadyConnected", false);
      provider = null;
      web3 = null;
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (currentChainId) {
      getBalance();
    }
  }, [currentChainId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBalance = () => {
    web3.eth.getBalance(account, function (e, result) {
      if (e) {
        console.error(e);
      } else {
        balance = web3.utils.fromWei(result, "ether");
        if (currentChainId) {
          setAccountBalance(parseFloat(balance).toFixed(4));
        }
      }
    });
  };

  async function callGetStakes() {
    let tempStakes = [];
    let stakes = await getStakes();
    tempStakes.push(stakes);
    setUserStakes(tempStakes);
    return stakes;
  }

  useEffect(() => {
    if (userStakes.length > 0) {
      getStakeAmount();
    }
  }, [userStakes]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStakeAmount = () => {
    let amount = 0;
    for (let i = 0; i < userStakes[0].length; i++) {
      amount = amount + parseInt(userStakes[0][i]["amount"]);
    }
    setUserStakeAmount(amount / 10 ** 18);
    return amount;
  };

  useEffect(() => {
    const isWeb3AlreadyConnected = localStorage.getItem(
      "isWeb3AlreadyConnected"
    );
    if (isWeb3AlreadyConnected === "true") {
      walletConnect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleDarkMode = () => {
    if (
      localStorage.getItem("theme") === "light" ||
      localStorage.getItem("theme") === ""
    ) {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
      document.documentElement.setAttribute("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
      document.documentElement.setAttribute("theme", "light");
    }
  };

  return (
    <div className="App" theme={localStorage.getItem("theme")}>
      <div className="socialBar">
        <SocialBar />
      </div>
      <AppContext.Provider
        value={{
          connectionStatus,
          connectedAccount,
          accountBalance,
          currentChainId,
          account,
          isCorrectNetworkForStake,
          isCorrectNetwork,
        }}
      >
        <Web3Context.Provider
          value={{ web3, provider, walletConnect, getBalance }}
        >
          <Navbar
            account={account}
            walletConnect={walletConnect}
            walletDisconnect={walletDisconnect}
            toggleDarkMode={toggleDarkMode}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  walletConnect={walletConnect}
                  walletDisconnect={walletDisconnect}
                  userStakeAmount={userStakeAmount}
                />
              }
            />
            <Route
              path="/bridge"
              element={<Bridge walletConnect={walletConnect} />}
            />
            <Route
              path="/status/:deposit_network/:tx_hash"
              element={<Status />}
            />
            <Route
              path="/stake"
              element={<StakeV2 getUserStakes={callGetStakes} />}
            />
            <Route
              path="/stake-detail/:index"
              element={<StakeDetail getUserStakes={callGetStakes} />}
            />
            <Route path="/wall" element={<Wall />} />
            <Route path="/post-detail/:id" element={<Post />} />
            <Route path="/burn" element={<Burn />} />
            <Route path="/vote" element={<Proposals />} />
            <Route path="/vote-detail/:id/:tab" element={<Detail />} />
            <Route path="/create-nft" element={<CreateNft />} />
            <Route path="/browse-nft" element={<BrowseNft />} />
            <Route
              path="/nft-detail/:contractAddress/:tokenId"
              element={<NftDetail />}
            />
          </Routes>
        </Web3Context.Provider>
      </AppContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
