import React from "react";
import styles from "./pagination.module.css";

export default function Pagination({ itemPerPage, totalItem, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItem / itemPerPage); i++) {
    pageNumbers.push(i);
  }
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={styles.container}>
      <div className="take-left"></div>
      <div className={styles.numbers}>
        {pageNumbers.map((number) => {
          return (
            <div
              onClick={() => { paginate(number); scrollTop(); }}
              key={number}
              className={styles.number}
            >
              {number}
            </div>
          );
        })}
      </div>
      <div className="take-right"></div>
    </div >
  );
}
