export function checkFormFields(
  amount,
  depositNetwork,
  withdrawNetwork,
  tokenSymbol,
  chainId
) {
  if (!depositNetwork || depositNetwork === "" || depositNetwork === "-1") {
    return "Sender network must be selected";
  }

  if (!withdrawNetwork || withdrawNetwork === "" || withdrawNetwork === "-1") {
    return "Destination network must be selected";
  }

  if (withdrawNetwork === depositNetwork) {
    return "Networks cannot be same";
  }

  if (!tokenSymbol || tokenSymbol === "" || tokenSymbol === "Select token") {
    return "You have to select a token to bridge";
  }

  if (!amount || amount === 0 || amount === "") {
    return "You have to enter an amount to bridge";
  }

  if (parseInt(chainId) !== parseInt(depositNetwork)) {
    return "Network on your Metamask and sender network must match!";
  }

  return "";
}
