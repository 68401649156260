import React, { useEffect, useState, useContext } from "react";
import styles from "./balances.module.css";
import { AppContext } from "../../../utils/Context";
import axios from "axios";

export default function BalancesCard() {
  const account = useContext(AppContext).account;
  const [ethBalance, setEthBalance] = useState();
  const [bnbBalance, setBnbBalance] = useState();
  const [avaxBalance, setAvaxBalance] = useState();
  const [omcBalance, setOmcBalance] = useState();

  useEffect(() => {
    const getEthBalance = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-eth-balance/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setEthBalance(res.data.result);
            clearInterval(getEthBalance);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getBnbBalance = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-bnb-balance/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setBnbBalance(res.data.result);
            clearInterval(getBnbBalance);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAvaxBalance = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-avax-balance/" + account
        )
        .then((res) => {
          if (parseInt(res.data.status) === 1) {
            setAvaxBalance(res.data.result);
            clearInterval(getAvaxBalance);
          }
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getOmcBalance = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL_HOME + "get-omc-balance/" + account
        )
        .then((res) => {
          setOmcBalance(res.data.result);
          clearInterval(getOmcBalance);
        });
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.header}>Balances</div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <div>ETH</div>
          <div>
            {ethBalance !== undefined
              ? parseFloat(ethBalance / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 5 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>BNB</div>
          <div>
            {bnbBalance !== undefined
              ? parseFloat(bnbBalance / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 5 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>AVAX</div>
          <div>
            {" "}
            {avaxBalance !== undefined
              ? parseFloat(avaxBalance / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 5 }
                )
              : "--"}
          </div>
        </div>
        <div className={styles.text}>
          <div>OMC</div>
          <div>
            {" "}
            {omcBalance !== undefined
              ? parseFloat(omcBalance / 1000000000000000000).toLocaleString(
                  "en",
                  { minimumFractionDigits: 5 }
                )
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
}
