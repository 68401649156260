import React, { useContext } from "react";
import styles from "./burn.module.css";
import EnterAmount from "../../components/BurnComponents/EnterBurnAmount/EnterAmount";
import BurnedCard from "../../components/BurnComponents/BurnedCard/BurnedCard";
import BalanceContainer from "../../components/StakeComponents/BalanceContainer/BalanceContainer";
import { AppContext } from "../../utils/Context";

export default function Burn() {
  const connectionStatus = useContext(AppContext).connectionStatus;
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <BurnedCard />
        <BalanceContainer />
        <EnterAmount connectionStatus={connectionStatus} />
      </div>
    </div>
  );
}
