import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./status.module.css";
import AmountCard from "../../components/BridgeComponents/AmountCard/AmountCard";
import TicketStatus from "../../components/BridgeComponents/TicketStatus/TicketStatus";
import TransferStatus from "../../components/BridgeComponents/TransferStatus/TransferStatus";
import ReceiveButton from "../../components/BridgeComponents/ReceiveButton/ReceiveButton";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_BRIDGE,
});

export default function Status() {
  const txHash = useParams().tx_hash;
  const depositNetwork = useParams().deposit_network;
  const [depositObject, setDepositObject] = useState("");
  const [ticketObject, setTicketObject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setInterval(() => {
      api
        .post("/deposit/status/", {
          txHash: txHash,
          depositNetwork: depositNetwork,
        })
        .then(function (response) {
          if (response.data.error === false) {
            setDepositObject(response.data.data.deposit);
            if (response.data.data.ticket !== null) {
              setTicketObject(response.data.data.ticket);
            }
          }
        })
        .catch(function () {
          return "error";
        });
    }, 5000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {depositObject === "" ? (
        <div className={styles.shimmer}>Loading...</div>
      ) : (
        <>
          <AmountCard depositObject={depositObject} setMessage={setMessage} />
          <div className={styles.statusContainer}>
            <div className={styles.title}>Transaction Status</div>
            <TicketStatus
              status={depositObject.status}
              depositObject={depositObject}
            />
            <ReceiveButton
              depositObject={depositObject}
              ticketObject={ticketObject}
              setMessage={setMessage}
            />
            <div className={styles.message}>{message}</div>
            <div className={styles.arrow}>
              <Arrow />
            </div>
            <TransferStatus
              status={depositObject.status}
              depositObject={depositObject}
            />
          </div>
        </>
      )}
    </div>
  );
}
