import React from "react";
import styles from "./card.module.css";
import { useNavigate } from "react-router-dom";

export default function NftCard({ name, image, id, tokenId, contractAddress }) {
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/nft-detail/${contractAddress}/${tokenId}`)}
    >
      <div className={styles.header}>
        <img src={image} alt=""></img>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.text}>{name}</div>
          <div className={styles.value}></div>
        </div>
      </div>
    </div>
  );
}
