import React, { useEffect, useState } from "react";
import styles from "./value.module.css";
import { ReactComponent as Icon } from "../../../assets/omcIcon.svg";
import { useContext } from "react";
import { AppContext } from "../../../utils/Context";
import axios from "axios";

export default function OmcValue() {
  const isConnected = useContext(AppContext);
  const [omcValue, setOmcValue] = useState(0);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL_HOME + "get-omc-value")
      .then((res) => setOmcValue(res.data.data.statistics.price));
  }, []);

  return (
    <div
      className={
        isConnected.connectionStatus
          ? `${styles.containerOrdered}`
          : `${styles.container}`
      }
    >
      <div>
        <Icon />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.strongText}>
          1 OMC = {omcValue.toFixed(4)} USD
        </div>
      </div>
    </div>
  );
}
